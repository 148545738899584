import { api } from './index';
import { extractResults, reportResultInSnack } from './util';
import { strings } from 'variables/messages';

export interface Option {
  uuid: string;
  label: string;
}

export const optionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProspectListOptions: builder.query<Option[], void>({
      query: () => ({
        url: '/api/v2/options/prospect_list/',
        method: 'GET',
      }),
      transformResponse: extractResults,
      providesTags: ['Options.ProspectList'],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: strings.options.error.prospectList,
          forceSuccess: true,
        });
      },
    }),
  }),
});
