import {
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Option, optionsApi } from 'redux/reducers/api/options';
import { Controller, useFormContext } from 'react-hook-form';
import { NewReportFieldValues } from './NewReport';
import { AutocompleteElement } from 'react-hook-form-mui';
import { getInputElementProps } from 'components/common/Modal/FormModal';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ButtonDateRangePicker } from 'components/common/Buttons/ButtonDateRangePicker';

export type ReportTitle =
  | 'Event insights report'
  | 'Sponsor reporting'
  | 'Event reporting'
  | 'Competitive analysis';

export const ReportSettings = () => {
  const form = useFormContext<NewReportFieldValues>();

  // get a list of my current prospect lists
  const { data: prospectLists, isFetching: fetchingProspectLists } =
    optionsApi.useGetProspectListOptionsQuery();

  const confirmedOnly = form.watch('confirmed_only');

  return (
    <Paper
      sx={{
        p: 4,
        flex: 1,
      }}
    >
      <Stack spacing={4}>
        <Typography variant="h6">Filter by:</Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={4}
        >
          <Grid item flex={1}>
            <Typography variant="body1">Prospect list:</Typography>
          </Grid>
          <Grid item xs={8}>
            <AutocompleteElement
              {...getInputElementProps({
                name: 'prospect_list',
                label: 'Prospect list',
                form,
              })}
              rules={{
                required: 'Select a prospect list',
              }}
              required
              aria-required
              options={prospectLists ?? []}
              autocompleteProps={{
                getOptionLabel: (option: Option) => option?.label ?? '',
                isOptionEqualToValue: (option: Option, value: Option) =>
                  option?.uuid === value?.uuid,
                disabled: fetchingProspectLists,
                size: 'small',
              }}
            />
          </Grid>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item flex={1}>
            <Typography variant="body1">Date filter:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              name={'filter_date'}
              rules={{
                required: true,
                validate: ([start, end]) => {
                  return Boolean(start && end) || 'Select a date range';
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <ButtonDateRangePicker
                    handleApply={(dateRange) => {
                      form.setValue('filter_date', dateRange);
                      form.trigger('filter_date');
                    }}
                    handleDelete={() => {
                      form.setValue('filter_date', [null, null]);
                    }}
                    existingDate={{
                      startDate: form.watch('filter_date')[0],
                      endDate: form.watch('filter_date')[1],
                    }}
                    slotProps={{
                      button: {
                        variantWhenSelected: 'outlined',
                        variantWhenNotSelected: 'outlined',
                        colorWhenSelected: 'grey',
                        disablePrependedDateLabel: true,
                        size: 'large',
                        fullWidth: true,
                        color: fieldState.error ? 'error' : 'grey',
                        children: fieldState.error && fieldState.error.message,
                        endIcon: (
                          <IconButton
                            size="small"
                            sx={{
                              padding: (theme) => theme.spacing(0.125),
                            }}
                          >
                            <ArrowDropDownIcon />
                          </IconButton>
                        ),
                        sx: {
                          justifyContent: 'space-between',
                          display: 'flex',
                          paddingX: (theme) => theme.spacing(0.75),
                          paddingRight: (theme) => theme.spacing(0.6),
                          paddingY: (theme) => theme.spacing(0.25),
                        },
                      },
                      popover: {
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                      },
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Typography variant="body1">Prospect status:</Typography>
          <Button
            onClick={() => {
              form.setValue('confirmed_only', !confirmedOnly);
            }}
            variant="outlined"
            color="grey"
            size="medium"
            startIcon={
              confirmedOnly ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
            }
          >
            Confirmed only
          </Button>
        </Stack>
      </Stack>
      {/* <Stack spacing={2}>
          <Typography variant="h6">Report type</Typography>
          <Typography>
            Select the type of events comparison report you'd like to create.{' '}
          </Typography>
          <Stack direction="row" gap={3} mb={8}>
            {reportTitles.map(({ key, title }, index) => (
              <ReportCardType
                key={index}
                setReportType={setReportType}
                value={key}
                title={title}
                activated={reportType === key}
                description=""
              />
            ))}
          </Stack>
        </Stack> */}
    </Paper>
  );
};
