import { useAuth0 } from '@auth0/auth0-react';
import { ProtectedAdminLayout } from 'routes/layouts/ProtectedAdminLayout';
import React, { ComponentType, useEffect, useMemo } from 'react';
import { RedirectingLabel } from './RedirectingLabel';
import { useUserState } from 'hooks/common/useUserState';
import { useLocation } from 'react-router-dom';

type WithAppAuthenticationRequiredOptions = {
  onBoarding?: boolean;
  noFooter?: boolean;
};

export function withAppAuthenticationRequired<TProps extends {}>(
  Component: ComponentType<TProps>,
  {
    onBoarding = false,
    noFooter = false,
  }: WithAppAuthenticationRequiredOptions = {}
) {
  return function WithAppAuthenticationRequired(props: TProps) {
    const { isAuthenticated, isFetching } = useUserState();

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const loginHint = searchParams.get('vdx_login_hint');
    const { loginWithRedirect } = useAuth0();

    const couldBecomeAuthenticated = useMemo(
      () => !isAuthenticated && isFetching,
      [isAuthenticated, isFetching]
    );
    useEffect(() => {
      if (isAuthenticated || couldBecomeAuthenticated) {
        return;
      }
      // definitely not authenticated
      (async () => {
        await loginWithRedirect({
          authorizationParams: loginHint
            ? {
                login_hint: loginHint ?? undefined,
                screen_hint: 'signup',
              }
            : undefined,
          appState: { returnTo: window.location.pathname },
        });
      })().catch(console.error);
    }, [
      isAuthenticated,
      couldBecomeAuthenticated,
      loginWithRedirect,
      loginHint,
    ]);

    if (couldBecomeAuthenticated) return null; // maybe add loading indicator
    return isAuthenticated ? (
      <ProtectedAdminLayout onBoarding={onBoarding} noFooter={noFooter}>
        <Component {...props} />
      </ProtectedAdminLayout>
    ) : (
      <RedirectingLabel />
    );
  };
}
