import React, { ReactNode, useState } from 'react';
import {
  Button,
  ButtonProps,
  Paper,
  Popper,
  PopperProps,
  ClickAwayListener,
  MenuList,
  MenuListProps,
} from '@mui/material';

export type ButtonWithPopperProps = {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onClose?: () => void;
  open?: boolean;
  label: string | JSX.Element;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
  popperProps?: Omit<PopperProps, 'anchorEl' | 'open'>;
  menuListProps?: Omit<MenuListProps, 'autoFocusItem'>;
  children: ReactNode;
};

/**
 * A component that renders a button that opens a popper. The button will automatically open the popper,
 * unless onclick, onClose, or open are provided, and then the component enters a controlled mode.
 */
export function ButtonWithPopper({
  label,
  buttonProps,
  popperProps,
  menuListProps,
  children,
  onClick,
  open: openProp,
  onClose,
}: ButtonWithPopperProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl && !onClick ? null : event.currentTarget);
    onClick && onClick(event);
  };

  const handleClickAway = () => {
    onClose ? onClose() : setAnchorEl(null);
  };

  const open = Boolean(onClick ? openProp : anchorEl);

  return (
    <>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        disableElevation
        disableFocusRipple
        disableRipple
        onClick={handleClick}
        {...buttonProps}
      >
        {label}
      </Button>
      <PopperForButton
        anchorEl={anchorEl}
        open={open}
        handleClickAway={handleClickAway}
        slotProps={{
          popper: popperProps,
          menuList: menuListProps,
        }}
      >
        {children}
      </PopperForButton>
    </>
  );
}

export function PopperForButton({
  anchorEl,
  open,
  handleClickAway,
  children,
  slotProps,
}: {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClickAway: () => void;
  children: ReactNode;
  slotProps?: {
    popper?: Omit<PopperProps, 'anchorEl' | 'open'>;
    menuList?: Omit<MenuListProps, 'autoFocusItem'>;
  };
}) {
  return (
    <Popper open={open} anchorEl={anchorEl} {...slotProps?.popper}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper onClick={handleClickAway}>
          <MenuList autoFocusItem={open} {...slotProps?.menuList}>
            {children}
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}
