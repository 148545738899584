import { WizardConfig } from '../../../../components/Wizard/WizardConfig';

export type ListWizardConfig = {};

export const listWizardConfig: WizardConfig & {} = {
  id: 'list-wizard-v1.2',
  title: 'List builder',
  basePath: '/app/lists/wizard/new',
  description:
    'Create a list of your leads, active pipeline, customers, or competitors to gain insights into the events they are attending.',
  questions: [
    {
      id: 'list-type',
      label: 'What type of list would you like to build?',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'multiple-choice',
      name: 'icp_params.list_type',
      answers: [
        {
          label: 'Leads',
          value: 'Leads',
          icon: 'LeaderboardOutlinedIcon',
          nextQuestionId: 'leads-1',
        },
        {
          label: 'Active Pipelines',
          value: 'Active pipeline',
          icon: 'FilterAltOutlinedIcon',
          nextQuestionId: 'pipeline-1',
        },
        {
          icon: 'CardMembershipOutlinedIcon',
          label: 'Customers',
          value: 'Customers',
          nextQuestionId: 'customers-1',
        },
        {
          label: 'Competitors',
          value: 'Competitors',
          icon: 'CompareArrowsIcon',
          nextQuestionId: 'competitors-1',
        },
      ],
    },
    // Start of pipeline flow
    {
      id: 'pipeline-1',
      label: 'How would you like to build your list?',
      // description: 'Et rerum autem ullam odio nemo et soluta sit.',
      type: 'multiple-choice',
      name: 'list_source',
      answers: [
        {
          label: 'Upload or input organizations.',
          value: 'Upload or input organizations',
          icon: 'CorporateFareOutlinedIcon',
          nextQuestionId: 'pipeline-1-input-upload', // Change to interim question (form)
        },
        {
          // Stays same
          label: 'Import CRM',
          value: 'CRM',
          nextQuestionId: 'pipeline-1a',
        },
      ],
    },
    {
      id: 'pipeline-1-input-upload',
      type: 'input-upload',
      subType: 'organizations',
      name: 'icp_params.organization_options',
      label:
        'Upload or input the organizations you want to include in your list.',
      description: 'Upload field requirements: Organization Name.',
      nextQuestionId: 'pipeline-2',
    },

    //  NOTE: these are related to CRM functionality.
    // {
    //   id: 'pipeline-1a',
    //   label:
    //     'What is the deal stage that represents the active pipeline you want to track? ',
    //   type: 'multiple-choice',
    //   name: 'icp_params.include_deal_stage_options',
    //   answers: [
    //     {
    //       label: 'Deal stage',
    //       nextQuestionId: 'pipeline-1b',
    //     },
    //   ],
    // },
    // {
    //   id: 'pipeline-1b',
    //   label: 'Do you want to narrow down your pipeline by Deal owner?',
    //   type: 'multiple-choice',
    //   name: 'icp_params.include_deal_owner_options',
    //   answers: [
    //     {
    //       label: 'Include deal owner',
    //       nextQuestionId: 'pipeline-1c',
    //     },
    //   ],
    // },
    // {
    //   id: 'pipeline-1c',
    //   label: 'Who do you want to exclude from your CRM import?',
    //   type: 'multiple-choice',
    //   name: 'icp_params.exclude_deal_owner_options',
    //   answers: [
    //     {
    //       label: 'Exclude deal owner',
    //       nextQuestionId: 'pipeline-2',
    //     },
    //   ],
    // },
    {
      id: 'pipeline-2',
      label:
        'Would you like to include specific individuals or job titles of people you are targeting at these organizations?',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'value',
        },
        {
          label: 'Specific people',
          value: 'Specific people',
          icon: 'PeopleOutlineIcon',
          nextQuestionId: 'pipeline-2a-people',
        },
        {
          label: 'Target personas',
          value: 'Target personas',
          icon: 'AutoAwesomeOutlinedIcon',
          nextQuestionId: 'pipeline-2b-personas',
        },
      ],
    },
    {
      id: 'pipeline-2a-people',
      label: 'How do you want to build this list specifically?',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      name: 'list_source',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Upload or input people.',
          value: 'Upload or input people',
          icon: 'PeopleOutlineIcon',
          nextQuestionId: 'pipeline-2a-people-input-upload',
        },
        {
          label: 'Import from CRM',
          value: 'CRM',
          icon: 'IntegrationInstructionsOutlinedIcon', // 'import-crm
          nextQuestionId: 'pipeline-2a-people-CRM',
        },
      ],
    },
    {
      id: 'pipeline-2a-people-input-upload',
      label: 'Upload or input the people you want to include in your list.',
      description: 'Upload field requirements: Full Name, Email.',
      type: 'input-upload',
      subType: 'people',
      name: 'icp_params.people_options',
      nextQuestionId: 'value',
    },
    // {
    //   id: 'pipeline-2a-people-CRM',
    //   label:
    //     'Who are the contacts, specific people you want to include in your active pipeline list?',
    //   type: 'multiple-choice',
    //   name: 'icp_params.contact_owner_options',
    //   answers: [
    //     {
    //       label: 'Include contact owner',
    //       nextQuestionId: 'pipeline-2a-people-CRM-include',
    //     },
    //   ],
    // },
    // {
    //   id: 'pipeline-2a-people-CRM-include',
    //   label:
    //     'Who are the contacts, specific people you want to exclude in your active pipeline list?',
    //   type: 'multiple-choice',
    //   // name: 'icp_params.contact_owner_options', we currently don't have a way to exclude contact owner
    //   answers: [
    //     {
    //       label: 'Exclude contact owner',
    //       nextQuestionId: 'value',
    //     },
    //   ],
    // },

    {
      id: 'pipeline-2b-personas',
      label: 'Input or upload the job titles you would like to target.',
      type: 'input-upload',
      subType: 'people',
      name: 'icp_params.include_job_titles',
      nextQuestionId: 'pipeline-2b-personas-include',
    },
    {
      id: 'pipeline-2b-personas-include',
      label: 'Input or upload the job titles you would like to target.',
      type: 'input-upload',
      subType: 'people',
      name: 'icp_params.include_job_titles',
      nextQuestionId: 'value',
    },
    // End of pipeline flow

    // Start of customers flow
    {
      id: 'customers-1',
      label: 'How do you want to build this list?',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      name: 'list_source',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Upload or input organizations.',
          value: 'Upload or input organizations',
          icon: 'CorporateFareOutlinedIcon',
          nextQuestionId: 'customers-1-input-upload',
        },
        {
          label: 'Import CRM',
          value: 'CRM',
          icon: 'IntegrationInstructionsOutlinedIcon',
          nextQuestionId: 'customers-1a',
        },
      ],
    },
    {
      id: 'customers-1-input-upload',
      subType: 'organizations',
      label: 'Upload or input organizations.',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'input-upload',
      name: 'icp_params.organization_options',
      nextQuestionId: 'customers-2',
    },
    // {
    //   id: 'customers-1a',
    //   label: 'What is the deal stage that represents your current customer? ',
    //   type: 'multiple-choice',
    //   name: 'icp_params.include_deal_stage_options',
    //   answers: [
    //     {
    //       label: 'Include deal stage',
    //       nextQuestionId: 'customers-2',
    //     },
    //   ],
    // },

    {
      id: 'customers-2',
      label: 'Who are the people you want to target at these organizations?',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'value',
        },
        {
          label: 'Specific people',
          value: 'Specific people',
          icon: 'PeopleOutlineIcon',
          nextQuestionId: 'customers-2a-people',
        },
        {
          label: 'Target personas',
          value: 'Target personas',
          icon: 'AutoAwesomeOutlinedIcon',
          nextQuestionId: 'customers-2b-personas',
        },
      ],
    },
    {
      id: 'customers-2a-people',
      label: 'How do you want to build this list specifically?',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      name: 'list_source',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Upload or input people.',
          value: 'Upload or input people',
          icon: 'PeopleOutlineIcon',
          nextQuestionId: 'customers-2a-people-input-upload',
          // name: 'people_options',
        },
        {
          label: 'Import from CRM',
          value: 'CRM',
          icon: 'IntegrationInstructionsOutlinedIcon',
          nextQuestionId: 'customers-2a-people-CRM',
        },
      ],
    },
    {
      id: 'customers-2a-people-input-upload',
      label: 'Upload or input people.',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'input-upload',
      name: 'icp_params.people_options',
      subType: 'people',
      nextQuestionId: 'value',
    },
    // {
    //   id: 'customers-2a-people-CRM',
    //   label:
    //     'Who are the contacts, specific people you want to include in your customers list ?',
    //   type: 'multiple-choice',
    //   name: 'icp_params.contact_owner_options',
    //   answers: [
    //     {
    //       label: 'Include contact owner',
    //       nextQuestionId: 'customers-2a-people-CRM-include',
    //     },
    //   ],
    // },
    // {
    //   id: 'customers-2a-people-CRM-include',
    //   label:
    //     'Who are the contacts, specific people you want to exclude in your customers list?',
    //   type: 'multiple-choice',
    //   // name: 'icp_params.contact_owner_options', exclude contact owner is currently not part of the icp_params
    //   answers: [
    //     {
    //       label: 'Exclude contact owner',
    //       nextQuestionId: 'value',
    //     },
    //   ],
    // },

    {
      id: 'customers-2b-personas',
      label:
        'Who are the contacts, specific people you want to include in your customers list?',
      type: 'input-upload',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      name: 'icp_params.include_job_titles',
      subType: 'people',
      nextQuestionId: 'customers-2b-personas-include',
      // answers: [
      //   {
      //     label: 'Include job titles/keywords',
      //     name: 'include_job_titles',
      //   },
      // ],
    },
    {
      id: 'customers-2b-personas-include',
      label:
        'Who are the contacts, specific people you want to exclude in your customers list',
      description: 'Exclude job titles/keywords',
      type: 'input-upload',
      name: 'icp_params.exclude_job_titles',
      subType: 'people',
      nextQuestionId: 'value',
    },

    // End of customers flow

    // start of competitors flow
    {
      id: 'competitors-1',
      label: 'How do you want to build this list?',
      name: 'list_source',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Upload or input organizations.',
          value: 'Upload or input organizations',
          icon: 'CorporateFareOutlinedIcon',
          nextQuestionId: 'competitors-1-input-upload',
        },
      ],
    },
    {
      id: 'competitors-1-input-upload',
      label: 'Upload or input organizations.',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'input-upload',
      name: 'icp_params.organization_options',
      subType: 'organizations',
      nextQuestionId: 'competitors-2',
    },
    {
      id: 'competitors-2',
      label: 'Who are the people you want to target at these organizations?',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'value',
        },
        {
          label: 'Target personas',
          value: 'Target personas',
          icon: 'AutoAwesomeOutlinedIcon',
          nextQuestionId: 'competitors-2b-personas',
        },
      ],
    },
    {
      id: 'competitors-2b-personas',
      label:
        'What are the specific job titles you want to include in your competitors list?',
      type: 'input-upload',
      subType: 'job-titles-keywords',
      name: 'icp_params.include_job_titles',
      nextQuestionId: 'competitors-2b-personas-include',
    },
    {
      id: 'competitors-2b-personas-include',
      label:
        'Who are the contacts, specific people you want to exclude in your competitors list',
      type: 'input-upload',
      name: 'icp_params.exclude_job_titles',
      subType: 'people',
      description: 'Exclude job titles/keywords',
      nextQuestionId: 'value',
    },
    // end of competitors flow

    // start of leads flow
    {
      id: 'leads-1',
      label: 'Who will be on your list ?',
      name: 'list_source',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'multiple-choice',
      answers: [
        {
          label: 'People',
          value: 'People',
          icon: 'PeopleOutlineIcon',
          nextQuestionId: 'leads-people-2',
        },
        {
          label: 'Organizations',
          value: 'Organizations',
          icon: 'CorporateFareOutlinedIcon',
          nextQuestionId: 'leads-organizations-2',
        },
        {
          label: 'Personas',
          value: 'Personas',
          icon: 'AutoAwesomeOutlinedIcon',
          nextQuestionId: 'leads-personas-2-input-upload',
        },
      ],
    },
    {
      id: 'leads-people-2',
      label: 'How would you like to build your list?',
      name: 'list_source',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Upload or input people.',
          value: 'Upload or input people',
          icon: 'PeopleOutlineIcon',
          nextQuestionId: 'leads-people-2-input-upload',
        },
        {
          label: 'Import CRM',
          value: 'CRM',
          icon: 'IntegrationInstructionsOutlinedIcon',
          nextQuestionId: 'leads-people-CRM',
        },
      ],
    },
    {
      label: 'Upload or input the people you want to include in your list.',
      id: 'leads-people-2-input-upload',
      description: 'Upload field requirements: full name, email.',
      type: 'input-upload',
      name: 'icp_params.people_options',
      subType: 'people',
      nextQuestionId: 'value',
    },
    // {
    //   id: 'leads-people-CRM',
    //   label: 'Who do you want to include from your CRM import?',
    //   type: 'multiple-choice',
    //   name: 'icp_params.crm_people_options',
    //   answers: [
    //     {
    //       label: 'Include contact owner',
    //       nextQuestionId: 'leads-people-CRM-include',
    //     },
    //   ],
    // },
    // {
    //   id: 'leads-people-CRM-include',
    //   label: 'Who do you want to exclude from your CRM import?',
    //   type: 'multiple-choice',
    //   // name:'icp_params.crm_people_options' we currently don't have a way to exclude contact owner
    //   answers: [
    //     {
    //       label: 'Exclude contact owner',
    //       nextQuestionId: 'value',
    //     },
    //   ],
    // },
    {
      id: 'leads-organizations-2',
      label: 'How do you want to build this list?',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      name: 'list_source',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Upload or input organizations below.',
          value: 'Upload or input organizations',
          icon: 'CorporateFareOutlinedIcon',
          nextQuestionId: 'leads-organizations-2-input-upload',
        },
        {
          label: 'Import CRM',
          value: 'CRM',
          icon: 'IntegrationInstructionsOutlinedIcon',
          nextQuestionId: 'leads-organizations-CRM',
        },
      ],
    },
    {
      id: 'leads-organizations-2-input-upload',
      label: 'Upload or input the organizations for your list below.',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'input-upload',
      name: 'icp_params.organization_options',
      subType: 'organizations',
      nextQuestionId: 'leads-organizations-personas',
    },
    // {
    //   id: 'leads-organizations-CRM',
    //   label: 'Who do you want to include from your CRM import?',
    //   type: 'multiple-choice',
    //   name: 'icp_params.crm_company_options',
    //   answers: [
    //     {
    //       label: 'Include company owner',
    //       nextQuestionId: 'leads-organizations-CRM-include',
    //     },
    //   ],
    // },
    // {
    //   id: 'leads-organizations-CRM-include',
    //   label: 'Who do you want to exclude from your CRM import?',
    //   type: 'multiple-choice',
    //   // name: 'icp_params.crm_company_options', we currently don't have a way to exclude company owner
    //   answers: [
    //     {
    //       label: 'Exclude company owner',
    //       nextQuestionId: 'leads-organizations-personas',
    //     },
    //   ],
    // },
    {
      id: 'leads-organizations-personas',
      label:
        'Are there specific personas you want to target within these organizations?',
      type: 'multiple-choice',
      answers: [
        // TODO: Alert opportunity missed
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'value',
        },
        {
          label: 'Target Personas',
          value: 'Target Personas',
          icon: 'AutoAwesomeOutlinedIcon',
          nextQuestionId: 'leads-organizations-personas-include',
        },
      ],
    },
    {
      id: 'leads-organizations-personas-include',
      label:
        'What target job titles do you want to include within these organizations?',
      type: 'input-upload',
      name: 'icp_params.include_job_titles',
      placeholder: 'Enter job titles or keywords to include',
      subType: 'job-titles-keywords',
      description: 'Include job titles/keywords',
      nextQuestionId: 'leads-organizations-personas-exclude',
    },
    {
      id: 'leads-organizations-personas-exclude',
      label:
        'Are there any job titles or keywords you want to exclude within these organizations?',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'value',
        },
        {
          label: 'Exclude job titles/keywords',
          value: 'Exclude job titles',
          icon: 'RemoveOutlinedIcon',
          nextQuestionId: 'leads-organizations-personas-exclude-input-upload',
        },
      ],
    },
    {
      id: 'leads-organizations-personas-exclude-input-upload',
      label:
        'Input or upload the job titles or keywords you would like to exclude from your list.',
      type: 'input-upload',
      name: 'icp_params.exclude_job_titles',
      placeholder: 'Enter job titles or keywords to exclude',
      subType: 'job-titles-keywords',
      nextQuestionId: 'value',
    },
    {
      id: 'leads-personas-2-input-upload',
      label: 'Enter or upload the target personas you want to include.',
      description: 'Include job titles or keywords to define your persona.',
      type: 'input-upload',
      name: 'icp_params.include_job_titles',
      placeholder: 'Enter job titles or keywords to include',
      subType: 'job-titles-keywords',
      nextQuestionId: 'leads-personas-exclude',
    },
    {
      id: 'leads-personas-exclude',
      label:
        'Are there specific job titles or keywords you would like to exclude from your list?',
      // description: 'Quia nesciunt quia et.',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'leads-personas-targetpersonas',
        },
        {
          label: 'Exclude job titles',
          value: 'Exclude job titles',
          icon: 'RemoveOutlinedIcon',
          nextQuestionId: 'leads-personas-exclude-input-upload',
        },
      ],
    },
    {
      id: 'leads-personas-exclude-input-upload',
      label:
        'Input or upload the job titles or keywords you would like to exclude from your list?',
      // description: 'Quia nesciunt quia et.',
      type: 'input-upload',
      name: 'icp_params.exclude_job_titles',
      placeholder: 'Enter job titles or keywords to exclude',
      subType: 'job-titles-keywords',
      nextQuestionId: 'leads-personas-targetpersonas',
    },
    {
      id: 'leads-personas-targetpersonas',
      label:
        'Are there specific organizations or organization attributes (e.g., size, industry) that you are targeting for individuals with these job titles?',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'value',
        },
        {
          label: 'Specific organizations',
          value: 'Specific organizations',
          icon: 'CorporateFareOutlinedIcon',
          nextQuestionId: 'leads-personas-targetpersonas-organizations',
        },
        {
          label: 'Organization attributes',
          value: 'Organization attributes',
          icon: 'EditAttributesOutlinedIcon',
          nextQuestionId: 'leads-personas-targetpersonas-orgattributes',
        },
      ],
    },
    {
      id: 'leads-personas-targetpersonas-orgattributes',
      label: 'Define the organization attributes you are targeting.',
      // description: 'Ea autem perferendis. Mollitia et quidem eaque.',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Organization size',
          value: 'Organization size',
          icon: 'GroupsOutlinedIcon',
          nextQuestionId:
            'leads-personas-targetpersonas-orgattributes-organization-size',
        },
        {
          label: 'Industry',
          value: 'Industry',
          icon: 'CategoryOutlinedIcon',
          nextQuestionId:
            'leads-personas-targetpersonas-organizations-industry',
        },
      ],
    },
    {
      id: 'leads-personas-targetpersonas-organizations-industry',
      label: 'What industries are you targeting?',
      type: 'tree-select-autocomplete',
      name: 'icp_params.industry',
      subType: 'sectors',
      nextQuestionId: 'value',
    },
    {
      id: 'leads-personas-targetpersonas-orgattributes-organization-size',
      label: 'What size organizations are you targeting?',
      name: 'icp_params.organization_size',
      type: 'input-upload',
      subType: 'organization-size',
      nextQuestionId: 'value',
    },
    {
      id: 'leads-personas-targetpersonas-organizations',
      label:
        'Input or upload the organizations you want to target, or select from a pre-existing list you’ve already created.',
      // description: 'In et voluptate.',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Upload or input organizations.',
          value: 'Upload or input organizations',
          icon: 'CorporateFareOutlinedIcon',
          nextQuestionId: 'leads-organizations-2-input-upload',
        },
        {
          label: 'Filter existing list',
          value: 'Filter existing list',
          nextQuestionId: 'leads-organizations-2-existing-list',
        },
        {
          label: 'Import CRM',
          value: 'CRM',
          icon: 'IntegrationInstructionsOutlinedIcon',
          nextQuestionId: 'leads-personas-targetpersonas-organizations-crm',
        },
      ],
    },
    {
      id: 'leads-organizations-2-existing-list',
      label: 'Select from a pre-existing list you’ve already created.',
      type: 'input-upload',
      subType: 'prospect-lists',
      name: 'icp_params.include_prospect_list_options',
      nextQuestionId: 'value',
    },
    // {
    //   id: 'leads-personas-targetpersonas-organizations-crm',
    //   label: 'Who do you want to include from your CRM import?',
    //   type: 'multiple-choice',
    //   name: 'icp_params.company_owner_options',
    //   answers: [
    //     {
    //       label: 'Include company owner',
    //       nextQuestionId: 'leads-personas-targetpersonas-organizations-crm-include',
    //     },
    //   ],
    // },
    // {
    //   id: 'leads-personas-targetpersonas-organizations-crm-include',
    //   label: 'Who do you want to exclude from your CRM import?',
    //   type: 'multiple-choice',
    //   // name: 'icp_params.company_owner_options', we currently don't have a way to exclude company owner
    //   answers: [
    //     {
    //       label: 'Exclude company owner',
    //       nextQuestionId: 'value',
    //     },
    //   ],
    // },
    {
      id: 'value',
      type: 'list-value',
      name: 'average_deal_size',
      label: 'Average value of prospect',
      description:
        'By assigning an average value to the leads on your list, you can gain greater insights into the potential ROI from the events you sponsor and attend.',
      nextQuestionId: 'list-name',
    },
    {
      id: 'list-name',
      name: 'name',
      label: 'What name would you like to give your list?',
      placeholder: 'Enter list name',
      type: 'text-input',
      nextQuestionId: 'share-list',
    },
    {
      id: 'share-list',
      label: 'Share list',
      description: 'Would you like to share this list with your team members ?',
      type: 'multiple-choice',
      answers: [
        {
          label: 'No',
          value: 'share',
          nextQuestionId: 'end',
        },
        {
          label: 'Share it with your team',
          value: 'share',
          nextQuestionId: 'share',
        },
      ],
    },
    {
      id: 'end',
      label: '',
      description: '',
      type: 'share',
      name: 'end',
    },
    {
      id: 'share',
      label: 'Share list',
      description: 'Share this list with your team members',
      type: 'share',
      name: 'share',
    },
  ],
};
