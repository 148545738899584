import React, { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import {
  sendAnalyticsEvent,
  trackPageView,
  trackUserId,
  usePendo,
} from 'analytics';
import { Routes } from 'routes';
import { Snackbar } from 'components/common/Widgets/Snackbar';
import { HelmetProvider } from 'react-helmet-async';
import { setUserIdentifiers } from 'redux/reducers/auth';
import { useAppDispatch } from 'redux/store';
import { useHistory } from 'react-router';
import { setStripeReturnValues } from 'redux/reducers/stripe_return';
import { useUserState } from 'hooks/common/useUserState';
import { userApi } from 'redux/reducers/api/user';
import { checkLoginFailed } from 'routes/auth/loginFailed';

export function App() {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    checkLoginFailed(dispatch);
  });
  const {
    email: userEmail,
    profile: { uuid: userId },
  } = useUserState();

  usePendo();

  useEffect(() => {
    if (userId) {
      trackUserId(userId, userEmail);
    }
    dispatch(setUserIdentifiers(userId ? { userId, email: userEmail } : null));
  }, [dispatch, userEmail, userId]);

  // send analytics event if user was last seen over an hour ago
  useEffect(() => {
    const lastSeen = localStorage.getItem('lastSeen');
    if (lastSeen) {
      const lastSeenDate = new Date(lastSeen);
      const now = new Date();
      const diff = Number(now) - Number(lastSeenDate);
      const diffInHours = diff / (1000 * 60 * 60);
      if (diffInHours > 1) {
        sendAnalyticsEvent(
          'User Interaction',
          'User active',
          location.pathname
        );
      }
    } else {
      sendAnalyticsEvent('User Interaction', 'User active', location.pathname);
    }
    localStorage.setItem('lastSeen', new Date().toISOString());
  }, [location]);

  const onIdle = () => {
    sendAnalyticsEvent('User Interaction', 'User inactive', location.pathname);
  };

  useIdleTimer({
    onIdle,
    timeout: 20 * 60 * 1000, // 20 minutes * second/minute * millisecond/second
  });

  const urlParams = new URLSearchParams(location.search);
  const return_values = {
    type: urlParams.get('type'),
    action: urlParams.get('action'),
    purchase: urlParams.get('purchase'),
    quantity: urlParams.get('quantity'),
    caller: urlParams.get('caller'),
    session_id: urlParams.get('sessionId'),
  };

  useEffect(() => {
    if (!!return_values.type) {
      dispatch(setStripeReturnValues(return_values));
      const redirect_path = window.location.pathname;
      history.push(redirect_path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [return_values]);

  const { listen: historyListen } = history;
  const { refetch } = userApi.useGetUserStateQuery();
  useEffect(() => {
    trackPageView();
    historyListen(trackPageView);
    historyListen(refetch);
  }, [historyListen, refetch]);

  // If you are using react-helmet-async on server side
  const helmetContext = {};

  return (
    <Suspense fallback={<> </>}>
      <HelmetProvider context={helmetContext}>
        <Routes />
        <Snackbar />
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
