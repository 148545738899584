import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { SimpleModal, SimpleModalProps } from './SimpleModal';
import PublishIcon from '@mui/icons-material/Publish';

// #region Types

interface FormModalProps<TValues extends FieldValues>
  extends Omit<SimpleModalProps, 'confirmText'> {
  onSubmit: (formValues: TValues) => Promise<void> | void;
  form: UseFormReturn<TValues>;
  defaultValues: TValues;
  confirmText?: string;
}

interface getInputElementPropsOptions<TValues extends FieldValues> {
  name: keyof TValues;
  label: string;
  form: UseFormReturn<TValues>;
}

// #endregion

// get the default input props for all react-hook-form-mui inputs, styled to be small and full width and no helperText
export function getInputElementProps<TValues extends FieldValues>({
  name,
  label,
  form,
}: getInputElementPropsOptions<TValues>) {
  return {
    name,
    label: form.formState.errors[name]
      ? String(form.formState.errors[name]?.message)
      : label,
    size: 'small' as const,
    fullWidth: true,
    parseError: () => '', // removes the helperText error message since we're using the label for the error message, prevents UI jumps when error appears
  };
}

// for DatePickerElement and TimePickerElement
export function getDateTimePickerInputProps<TValues extends FieldValues>({
  name,
  form,
}: Omit<getInputElementPropsOptions<TValues>, 'label'>) {
  return {
    inputProps: {
      size: 'small' as const,
      color: form.formState.errors[name] ? 'error' : undefined,
      focused: form.formState.errors[name] ? true : undefined,
    },
  };
}

// a SimpleModal with a form inside using react-hook-form
export function FormModal<TValues extends FieldValues>({
  children,
  form,
  onSubmit,
  defaultValues,
  ...simpleModalProps
}: PropsWithChildren<FormModalProps<TValues>>) {
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  // focus the first field when the modal opens
  useEffect(() => {
    const firstFieldName = Object.keys(defaultValues)[0] as Path<TValues>;
    if (simpleModalProps.open && firstFieldName) {
      setTimeout(() => {
        form.setFocus(firstFieldName);
      }, 0);
    }
  }, [form, simpleModalProps.open, defaultValues]);

  const handleFormSubmit = useCallback(async () => {
    try {
      setConfirmLoading(true); // the confirm / submit button is now loading
      await form.handleSubmit(async (values) => {
        await onSubmit(values); // submit this form to the server
        setConfirmLoading(false); // button is no longer loading
        form.reset(defaultValues); // reset the form after submission
        simpleModalProps.setOpen(false); // close the modal after submission
      })();
    } catch (error) {
      form.reset(defaultValues); // reset the form if error
      setConfirmLoading(false); // button is no longer loading
    }
  }, [form, onSubmit, defaultValues, simpleModalProps, setConfirmLoading]);

  return (
    <SimpleModal
      {...simpleModalProps}
      confirmLoading={confirmLoading}
      closeAfterSubmit={false} // we handle closing the modal ourselves above
      disableConfirm={
        // disable the confirm button if the form is submitting or invalid
        form.formState.isSubmitting ||
        !form.formState.isValid ||
        simpleModalProps.disableConfirm // force disable if the prop is set
      }
      confirmIcon={simpleModalProps.confirmIcon ?? <PublishIcon />} // default to PublishIcon if not provided
      confirmText={simpleModalProps.confirmText ?? 'Submit'} // default to 'Submit' if not provided
      onConfirm={handleFormSubmit}
      onCancel={() => {
        form.reset(defaultValues); // reset the form when the modal is closed
        simpleModalProps.onCancel?.(); // call the onCancel prop if provided
      }}
    >
      <FormContainer
        formContext={form}
        onSuccess={(data, event) => {
          event?.preventDefault();
          handleFormSubmit();
        }}
      >
        {children}
        {/* a hidden button for enter submission because FormContainer doesn't create a submit button automatically */}
        <input type="submit" style={{ display: 'none' }} />
      </FormContainer>
    </SimpleModal>
  );
}
