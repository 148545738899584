import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import SkipNextIcon from '@mui/icons-material/SkipNext';

/**
 * Maps strings to MUI icons. Used for selecting icons as a configuration parameter, such as in the list wizard.
 */
export const MuiIconManifest = {
  LeaderboardOutlinedIcon: LeaderboardOutlinedIcon,
  CardMembershipOutlinedIcon: CardMembershipOutlinedIcon,
  CompareArrowsIcon: CompareArrowsIcon,
  FilterAltOutlinedIcon: FilterAltOutlinedIcon,
  PeopleOutlineIcon: PeopleOutlineIcon,
  CorporateFareOutlinedIcon: CorporateFareOutlinedIcon,
  AutoAwesomeOutlinedIcon: AutoAwesomeOutlinedIcon,
  UploadFileOutlinedIcon: UploadFileOutlinedIcon,
  IntegrationInstructionsOutlinedIcon: IntegrationInstructionsOutlinedIcon,
  AddOutlinedIcon: AddOutlinedIcon,
  RemoveOutlinedIcon: RemoveOutlinedIcon,
  EditAttributesOutlinedIcon: EditAttributesOutlinedIcon,
  ArticleOutlinedIcon: ArticleOutlinedIcon,
  GroupsOutlinedIcon: GroupsOutlinedIcon,
  CategoryOutlinedIcon: CategoryOutlinedIcon,
  SkipNextIcon: SkipNextIcon,
};

export type MuiIconName = keyof typeof MuiIconManifest;
