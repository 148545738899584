import {
  WizardQuestion,
  WizardTreeSelectAutocompleteQuestion,
} from '../WizardConfig';
import { Stack, Typography } from '@mui/material';
import { WizardPagination } from '../WizardPagination';
import { useForm } from 'react-hook-form-mui';
import { Wizard } from '../Wizard';
import { useHistory } from 'react-router';
import { type Option } from 'hooks/Prospects/useIcpFilter';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnacksQueue } from 'redux/reducers/snacks';
import {
  Category,
  Option as TreeSelectOption,
  TreeSelectAutocomplete,
} from 'components/common/Inputs/TreeSelectAutocomplete';
import { industryApi } from 'redux/reducers/api/industry';

export function TreeSelectAutocompleteQuestion({
  question,
  onSubmit,
  initialValue = [],
  onItemSelected,
  wizard,
}: {
  wizard: Wizard;
  question: WizardQuestion & WizardTreeSelectAutocompleteQuestion;
  onSubmit?: (response: any) => void;
  initialValue?: TreeSelectOption<string>[];
  onItemSelected?: (values: any[]) => void;
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultValues: Record<string, any> = {};

  defaultValues[question.name] = initialValue;

  const form = useForm({
    defaultValues,
  });

  const [value, setValue] = useState<Option[]>(initialValue || []);

  function handleChange(newValue: Option[]) {
    setValue(newValue);
    if (onItemSelected) {
      onItemSelected(newValue);
    }
  }

  function submitForm() {
    try {
      wizard.storage.saveResponse(question.id, value);
      history.push(wizard.questions.urlFor(question.nextQuestionId as string));
    } catch (e) {
      // Localstorage can't handle this much data; show a nice message.
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        dispatch(
          setSnacksQueue({
            message:
              'Your upload is too large for the wizard to handle. Try again with fewer rows.',
          })
        );
      }
    }
  }

  function onNextClick() {
    form.handleSubmit(submitForm)();
  }

  function onPreviousClick() {
    history.goBack();
  }

  const [getSubindustries, subindustryData] =
    industryApi.useLazyGetSubindustriesQuery();
  const [getIndustries, industryData] = industryApi.useLazyGetIndustriesQuery();
  const [getIndustryGroups, industryGroupData] =
    industryApi.useLazyGetIndustryGroupsQuery();
  const [getSectors, sectorData] = industryApi.useLazyGetSectorsQuery();

  let categoriesData: Category<string>[] = [];
  switch (question.subType) {
    case 'subindustries':
      categoriesData = subindustryData?.data || [];
      break;
    case 'industries':
      categoriesData = industryData?.data || [];
      break;
    case 'industry-groups':
      categoriesData = industryGroupData?.data || [];
      break;
    case 'sectors':
      categoriesData = sectorData?.data || [];
      break;
    default:
      throw new Error(`Unknown question subType: ${question.subType}`);
  }

  // conditionally trigger lazy RTK queries based on subType
  useEffect(() => {
    switch (question.subType) {
      case 'subindustries':
        getSubindustries();
        break;
      case 'industries':
        getIndustries();
        break;
      case 'industry-groups':
        getIndustryGroups();
        break;
      case 'sectors':
        getSectors();
        break;
      default:
        throw new Error(`Unknown question subType: ${question.subType}`);
    }
  }, [
    question.subType,
    getSubindustries,
    getIndustries,
    getIndustryGroups,
    getSectors,
  ]);

  return (
    <form onSubmit={form.handleSubmit(submitForm)}>
      <Stack spacing={8}>
        <Stack spacing={2}>
          <Typography fontWeight={500} variant="h6">
            {question.label}
          </Typography>
          {question.description ? (
            <Typography variant="body2">{question.description}</Typography>
          ) : null}
          <TreeSelectAutocomplete
            categories={categoriesData}
            customHandleChange={handleChange}
            initialValue={initialValue}
            slotProps={{
              autocomplete: {
                isOptionEqualToValue: (option, value) =>
                  option.value === value.value && option.label === value.label,
              },
            }}
          />
        </Stack>
        <WizardPagination
          wizard={wizard}
          question={question}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      </Stack>
    </form>
  );
}
