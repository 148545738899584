import { ReportSettings } from './ReportSettings';
import { Stack, Button, IconButton, Grid } from '@mui/material';
import { ReportSidebar } from './ReportSidebar';
import { withAppAuthenticationRequired } from 'components/common/Auth/withAppAuthenticationRequired';
import { eventReportsApi, ReportKind } from 'redux/reducers/api/reports';
import { Dayjs } from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Option } from 'redux/reducers/api/options';
import { SectionLayout } from 'components/common/Widgets/SectionLayout';
import { Close } from '@mui/icons-material';

const formDefaultValues = {
  name: '',
  prospect_list: undefined as Option | undefined,
  kind: 'insights' as ReportKind,
  filter_date: [null, null] as DateRange<Dayjs>,
  confirmed_only: false,
};

export type NewReportFieldValues = typeof formDefaultValues;

const _NewReport = () => {
  const form = useForm({
    defaultValues: formDefaultValues,
    mode: 'all',
  });

  const [postEventReport] = eventReportsApi.usePostEventReportMutation();
  const { push } = useHistory();

  const submitFn = form.handleSubmit((fieldValues) => {
    const { filter_date, prospect_list, ...rest } = fieldValues;
    if (!filter_date || !filter_date[0] || !filter_date[1] || !prospect_list) {
      // should never get here after validation
      throw new Error('Invalid date range');
    }
    postEventReport({
      ...rest,
      prospect_list: prospect_list.uuid,
      filter_date_start: filter_date[0].toISOString().split('T')[0],
      filter_date_end: filter_date[1].toISOString().split('T')[0],
    }).then(() => {
      push(`/app/events/search?reports=true`);
    });
  });

  console.log(form.formState.errors);

  return (
    <SectionLayout
      title="New report"
      slotProps={{
        container: {
          maxWidth: 'md',
        },
      }}
      rightsideToolbar={
        <IconButton onClick={() => push('/app/events/search?reports=true')}>
          <Close />
        </IconButton>
      }
    >
      <Stack spacing={4}>
        <FormContainer<NewReportFieldValues> formContext={form}>
          <Grid
            container
            spacing={3}
            alignItems="stretch"
            justifyContent="space-between"
          >
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <ReportSidebar />
            </Grid>
            <Grid item xs={8} sx={{ flexGrow: 1 }}>
              <ReportSettings />
            </Grid>
          </Grid>
        </FormContainer>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Button
            variant="roundedText"
            onClick={() => push('/app/events/search?reports=true')}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              form.trigger().then((isValid) => {
                console.log('Form is valid:', isValid);
                console.log('Form errors:', form.formState.errors);
              });
            }}
          >
            test
          </Button>
          <Button
            variant="rounded"
            onClick={submitFn}
            disabled={!form.formState.isValid || form.formState.isSubmitting}
          >
            Create Report
          </Button>
        </Stack>
      </Stack>
    </SectionLayout>
  );
};

export const NewReport = withAppAuthenticationRequired(_NewReport);
export default NewReport;
