import { api } from './index';
import { strings } from 'variables/messages';
import { endpoints } from 'variables/endpoint-urls';
import { ApiResponse, ApiResponseBase } from 'utils/api-response';
import { extractResults, reportResultInSnack } from './util';

// #region types

export type ReportKind = 'insights';

export type EventReportBody = {
  name: string;
  kind: ReportKind;
  prospect_list: string;
  filter_date_end: string;
  filter_date_start: string;
  confirmed_only: boolean;
};

export interface GetEventReportsResultsItem {
  uuid: string;
  name: string;
  kind: string;
  prospect_list: {
    uuid: string;
    name: string;
  };
  filter_date_end: string;
  filter_date_start: string;
  confirmed_only: boolean;
  prospect_list_valid: boolean;
}

export type GetEventReportsResults = GetEventReportsResultsItem[];

export type EventReportEvent = {
  event_uuid: string;
  event_name: string;
  event_type: string;
  date_end: string;
  date_start: string;
  matches: number;
  total_attendee_count: number;
  city: string;
  country: string;
  production_app_url: string;
  name: string;
  uuid: string;
};

export interface GetEventReportEventsResults {
  is_limited: boolean;
  events: EventReportEvent[];
}
// #endregion

const successMessages = strings.reports.success;
const errorMessages = strings.reports.error;

export const eventReportsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEventReports: builder.query<GetEventReportsResults, void>({
      query: () => ({
        url: endpoints.reports.get.eventReports,
        method: 'GET',
      }),
      transformResponse: extractResults,
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.eventReports,
          forceSuccess: true,
        });
      },
      providesTags: (result) =>
        result
          ? [
              'EventReports',
              ...result.map((report: GetEventReportsResultsItem) => ({
                type: 'EventReports' as const,
                uuid: report.uuid,
              })),
            ]
          : ['EventReports'],
    }),

    getEventReportEvents: builder.query<
      GetEventReportEventsResults,
      { uuid: string }
    >({
      query: ({ uuid }) => ({
        url: endpoints.reports.get.singleEventReport(uuid),
        method: 'GET',
      }),
      transformResponse: extractResults,
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.eventReports,
          forceSuccess: true,
        });
      },
      providesTags: (response, error, { uuid }) => [
        { type: 'EventReports', uuid },
      ],
    }),

    /**
     * Mutation function to create a new event report.
     * @param newEventReport The new event report to create.
     * @returns An API response containing the created event report.
     */
    postEventReport: builder.mutation<
      ApiResponse<EventReportBody>,
      EventReportBody
    >({
      query: (newEventReport) => ({
        url: endpoints.reports.post.createReport,
        method: 'POST',
        body: newEventReport,
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.createReport,
          defaultErrorMessage: errorMessages.createReport,
        });
      },
      invalidatesTags: ['EventReports'],
    }),

    /**
     * Mutation function to update an existing event report.
     * @param updatedEventReport The updated event report.
     * @returns An API response indicating the success or failure of the update.
     */
    putEventReport: builder.mutation<
      ApiResponse<unknown>,
      EventReportBody & { uuid: string }
    >({
      query: ({ uuid, ...updatedEventReport }) => ({
        url: endpoints.reports.put.updateReport(uuid),
        method: 'PUT',
        body: updatedEventReport,
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: 'Event report updated successfully.',
          defaultErrorMessage: 'Failed to update event report.',
        });
      },
      invalidatesTags: (response, error, { uuid }) => [
        { type: 'EventReports', uuid },
      ],
    }),

    deleteEventReport: builder.mutation<ApiResponseBase, string>({
      query: (uuid) => ({
        url: endpoints.reports.delete.deleteReport(uuid),
        method: 'DELETE',
      }),
      invalidatesTags: (response, error, uuid) => [
        { type: 'EventReports', uuid },
      ],
      transformResponse: extractResults,
      onQueryStarted: (uuid, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.deleteReport,
          defaultErrorMessage: errorMessages.deleteReport,
          forceSuccess: true,
        });
      },
    }),
  }),
});
