// docs: https://developers.google.com/analytics/devguides/collection/upgrade/analyticsjs
import { ANALYTICS_ID } from './variables/constants';
import { store } from './redux/store';
import { isProdSite } from './utils/util';

import { useUserState } from './hooks/common/useUserState';
import { useEffect, useMemo } from 'react';

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    gtag(
      method: string | undefined,
      action: string | undefined,
      props?: Record<string, any>
    ): void;

    pendo: {
      getVisitorId?: () => string;
      track(category: string, props?: Record<string, any>): void;
      initialize(config: Record<string, any>): void;
      isReady?: () => boolean;
      identify(config: Record<string, any>): void;
      clearSession(): void;
      isAnonymousVisitor(): boolean;
      teardown(): void;
    };
  }
}

export function usePendo() {
  const { isAuthenticated, email, profile, team, subscription, impersonating } =
    useUserState();

  const pendoIdentity = useMemo(() => {
    return !isAuthenticated
      ? {
          visitor: { id: 'VISITOR-UNIQUE-ID' },
        }
      : {
          visitor: {
            id: profile.uuid,
            email,
            name: profile.name,
            subscription: subscription,
          },
          account: {
            id: team.uuid,
            team: team.name,
          },
        };
  }, [
    email,
    isAuthenticated,
    profile.name,
    profile.uuid,
    subscription,
    team.name,
    team.uuid,
  ]);

  /*
   * useEffect won't catch Pendo becoming ready because that doesn't trigger a React re-render. The effect that follows
   * this one should take care of that. Here we just re-identify the user when we have new information about them.
   */
  useEffect(() => {
    if (window.pendo.isReady?.()) {
      if (!impersonating) {
        console.debug('Identifying in Pendo', pendoIdentity);
        window.pendo.identify(pendoIdentity);
      }
    }
  }, [pendoIdentity, impersonating]);

  // Runs on every render but should only do something once when pendo isn't ready.
  useEffect(() => {
    if (
      window.pendo.isReady && // Pendo has loaded
      !window.pendo.isReady() // Pendo hasn't been initialized
    ) {
      if (!impersonating) {
        console.debug('Initializing Pendo', pendoIdentity);
        window.pendo.initialize(pendoIdentity); // pendo becomes ready after this
      }
    }
  });

  useEffect(() => {
    if (impersonating) {
      console.debug('Impersonated session, disabling Pendo');
      window?.pendo?.teardown?.();
    }
  }, [impersonating]);
}

export function trackUserId(userId: string, email: string) {
  try {
    if (userId) {
      window.gtag('config', ANALYTICS_ID, {
        custom_map: { dimension1: 'User ID', dimension2: 'User Email' }, // This User ID is custom dimension
        send_page_view: false,
        user_id: userId, // This user id used for User-ID feature here: https://support.google.com/analytics/answer/3123662?hl=en#zippy=%2Cin-this-article
        email,
        ...(!isProdSite() ? { debug_mode: true } : {}),
      });
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * Send a user interaction event to Google Analytics.
 *
 * @param category The GA event category.
 * @param action The GA event action
 * @param label The GA event label.
 * @param value The GA event value, or any additional custom event properties.
 */
export function sendAnalyticsEvent(
  category: string,
  action: string,
  label?: string,
  value?: number | object
) {
  try {
    const { userId, email } = store.getState().auth.userIdentifiers ?? {
      userId: undefined,
      email: undefined,
    };
    window.gtag('event', action, {
      event_category: category,
      ...(label ? { event_label: label } : {}),
      ...(value ? (typeof value === 'object' ? value : { value }) : {}),
      ...(userId ? { 'User ID': userId } : {}),
      ...(email ? { 'User Email': email } : {}),
    });
  } catch (e) {
    console.error(e);
  }
}

export function trackPageView() {
  try {
    const params = new URLSearchParams(window.location.search);
    [...params].filter(([key, _val]) => key.startsWith('utm_'));
    const { userId, email } = store.getState().auth.userIdentifiers ?? {
      userId: undefined,
      email: undefined,
    };
    window.gtag('event', 'page_view', {
      page_location: `${window.location.origin}${window.location.pathname}`,
      page_path: window.location.pathname,
      page_title: document.title,
      ...(userId ? { 'User ID': userId } : {}),
      ...(email ? { 'User Email': email } : {}),
    });
  } catch (e) {
    console.error(e);
  }
}
