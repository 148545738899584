import React, { SyntheticEvent } from 'react';
import { Button, Grid } from '@mui/material';
import { WizardQuestion } from './WizardConfig';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Wizard } from './Wizard';

/**
 * Prev/next buttons for use in wizard question components.
 *
 * This is included manually in each question component since:
 * 1. Not all question types have pagination.
 * 2. Question types operate a bit differently.
 *
 * In the future we may make this more generic and include it once within <WizardQuestionPage />.
 */
export function WizardPagination({
  wizard,
  question,
  onNextClick,
  onPreviousClick,
  canGoBack = true,
  canGoForward = true,
}: {
  wizard: Wizard;
  question: WizardQuestion;
  onPreviousClick: (e: SyntheticEvent) => void;
  onNextClick?: (e: SyntheticEvent) => void;
  finalQuestion?: boolean;
  canGoBack?: boolean;
  canGoForward?: boolean;
}) {
  const previousButtonText = wizard.getPreviousButtonText(question.id);
  const nextButtonText = wizard.getNextButtonText(question.id);
  return (
    <Grid container mt={20} alignItems="center" justifyContent="space-between">
      <Grid item>
        {canGoBack ? (
          <Button
            variant="outlined"
            onClick={onPreviousClick}
            startIcon={<ArrowBackIcon />}
          >
            {previousButtonText}
          </Button>
        ) : null}
      </Grid>
      <Grid ml={2} item>
        {canGoForward ? (
          <Button
            variant="contained"
            onClick={onNextClick}
            endIcon={<ArrowForwardIcon />}
          >
            {nextButtonText}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
}
