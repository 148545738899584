import {
  WizardAnswer,
  WizardConfig,
  WizardQuestion,
  WizardStorage,
} from './WizardConfig';
import { type Option } from './WizardConfig';

export const STORAGE_PREFIX = 'vdx:wiz';

export class Wizard {
  basePath: string;
  config: WizardConfig;

  get localStorageKey() {
    return `${STORAGE_PREFIX}:${this.config.id}`;
  }

  // Simulate a chainable API (wizard.questions.etc())
  questions = {
    byId: this.getQuestionById.bind(this),
    urlFor: this.getUrlForQuestionId.bind(this),
    valueForMCAnswer: this.getValueForMultipleChoiceAnswer.bind(this),
  };

  // wizard.storage.etc()
  storage = {
    clear: this.clearStorage.bind(this),
    fetch: this.fetchStorage.bind(this),
    fetchResponse: this.fetchResponseForQuestion.bind(this),
    saveResponse: this.saveResponseForQuestion.bind(this),
    update: this.updateStorage.bind(this),
    getFormValuesMap: this.getFormPayload.bind(this),
    responseHasChanged: this.responseHasChangedForMCQuestion.bind(this),
    clearSubsequentResponses: this.clearSubsequentResponses.bind(this),
  };

  constructor(config: WizardConfig) {
    this.basePath = config.basePath;
    this.config = config;
  }

  getQuestionById(id: WizardQuestion['id']): WizardQuestion {
    const q = this.config.questions.find((q) => q.id === id);

    if (!q) {
      throw new Error(`Question with id ${id} not found.`);
    }

    return q;
  }

  getUrlForQuestionId(id: WizardQuestion['id']): string {
    return `${this.basePath}/${id}`;
  }

  /**
   * Save a response for a question.
   *
   * @param questionId
   * @param value
   */
  saveResponseForQuestion(questionId: WizardQuestion['id'], value: any) {
    const storageState = this.fetchStorage();

    const index = storageState.responses.findIndex(
      (r) => r.questionId === questionId
    );

    const newValue = {
      questionId,
      value,
    };

    //
    // if a response to this question exists, replace it in the same position.
    //
    if (index !== -1) {
      storageState.responses[index] = newValue;
    } else {
      //
      // Otherwise add it at the end.
      //
      storageState.responses.push(newValue);
    }

    this.storage.update(storageState);
  }

  /**
   * Retrieve desearialized storage state.
   *
   * @returns {WizardStorage}
   */
  fetchStorage(): WizardStorage {
    let storageString = localStorage.getItem(this.localStorageKey);
    let storageState;

    if (storageString) {
      storageState = JSON.parse(storageString) as WizardStorage;
    } else {
      storageState = {
        wizardId: this.config.id,
        responses: [],
      };
    }

    return storageState;
  }

  /**
   * Replace storage state with a new one.
   *
   * @param storageState
   */
  updateStorage(storageState: WizardStorage) {
    localStorage.setItem(this.localStorageKey, JSON.stringify(storageState));
  }

  clearStorage() {
    localStorage.removeItem(this.localStorageKey);
  }

  /**
   * Generate value for localStirage from the label.
   * NOTE: this will change!
   *
   * @param question
   * @param answer
   * @returns
   */
  getValueForMultipleChoiceAnswer(
    question: WizardQuestion,
    answer: WizardAnswer
  ) {
    return answer.value;
  }

  /**
   * Retrieve a question's response from localStorage, if it exists.
   *
   * @param questionId
   */
  fetchResponseForQuestion(questionId: WizardQuestion['id']) {
    const storageState = this.fetchStorage();

    return storageState.responses.find((r) => r.questionId === questionId);
  }

  /**
   * Retrieve all entered values from localstorage.
   * Return a structure with nested keys, defined by dotted 'name' attributes,
   *  that corresponds to the structure of the API payload.
   *
   * TODO: this will be extracted out when needed, since it's not reusable for other wizards.
   */
  getFormPayload() {
    const responses = this.fetchStorage().responses;
    const formValues: any = {};

    responses.forEach(({ questionId, value }) => {
      const question = this.getQuestionById(questionId);
      // Questions without names are wizard-only; they don't get sent to the API.
      if (!question.name) {
        return;
      }
      const nameParts = question.name.split('.');

      let current = formValues;

      for (let i = 0; i < nameParts.length; i++) {
        const part = nameParts[i];
        if (i === nameParts.length - 1) {
          current[part] = value;
        } else {
          current[part] = current[part] || {};
          current = current[part];
        }
      }
    });

    if (formValues.icp_params) {
      // Strip Option params down to their value attributes.
      const cleanedParams = Object.entries(formValues.icp_params)
        .map(([key, value]) => {
          if (Array.isArray(value) && value[0]?.value) {
            return [key, (value as Option[]).map((v: any) => v.value)];
          }
          return [key, value];
        })
        .reduce((acc: Record<string, any>, [key, value]) => {
          acc[key as string] = value;
          return acc;
        }, {});

      formValues.icp_params = cleanedParams;
    }

    return formValues;
  }

  /**
   * Gets the text for the "Previous" button based on the current question ID.
   * This method will later be moved to a wizard-specific class to tailor button text per wizard type.
   * @param {string} currentQuestionId - The ID of the current question being displayed.
   * @returns {string} The text to display on the "Previous" button.
   */
  getPreviousButtonText(currentQuestionId: WizardQuestion['id']): string {
    const index = this.config.questions.findIndex(
      (q) => q.id === currentQuestionId
    );

    return index === 0 ? 'Cancel' : 'Previous';
  }

  /**
   * Gets the text for the "Next" button based on the current question ID.
   * This method will later be moved to a wizard-specific class to tailor button text per wizard type.
   * @param {string} currentQuestionId - The ID of the current question being displayed.
   * @returns {string} The text to display on the "Next" button.
   */
  getNextButtonText(currentQuestionId: WizardQuestion['id']): string {
    const index = this.config.questions.findIndex(
      (q) => q.id === currentQuestionId
    );

    // TODO: This is specific to the list wizard. extract to a list-wizard specific class.
    if (currentQuestionId === 'list-name') {
      return 'Create list';
    }

    // Determine if this is the last question
    if (index === this.config.questions.length - 1) {
      return 'Finish';
    } else {
      return 'Next';
    }
  }

  /**
   * Clear saved responses for the given question and any questions that follow it.
   * Used when we change our answer for a multiple choice question, so that we don't
   *  end up with a response history that intersperses responses from different paths.
   *
   * @param questionId The starting question to clear.
   */
  clearSubsequentResponses(questionId: WizardQuestion['id']) {
    const storageState = this.fetchStorage();

    const questionIndex = this.config.questions.findIndex(
      (q) => q.id === questionId
    );

    if (questionIndex === -1) {
      return;
    }

    storageState.responses = storageState.responses.slice(0, questionIndex);

    this.storage.update(storageState);
  }

  responseHasChangedForMCQuestion(
    questionId: WizardQuestion['id'],
    newValue: any
  ): boolean {
    const existingResponse = this.storage.fetchResponse(questionId);

    return !!existingResponse?.value && existingResponse?.value !== newValue;
  }
}
