import axios from 'axios';

export type ApiResponseBase<TPayload extends {} = {}> =
  | (TPayload & {
      success: true;
      message: string;
    })
  | {
      success: false;
      message: string;
    };

export type ApiResponse<
  TData,
  TDataKey extends string = 'results'
> = ApiResponseBase<{ [k in TDataKey]: TData }>;

export type ApiEmptyResponse = ApiResponseBase<{}>;

interface ApiError {
  data: {
    detail: Record<string, any>;
    type: string;
    success: false;
    message: string;
  };
}

function isMaybeObject<T extends object>(val: unknown): val is Partial<T> {
  return typeof val === 'object' && val != null;
}

function isApiErrorData(val: unknown): val is ApiError['data'] {
  return (
    isMaybeObject<ApiError['data']>(val) &&
    'success' in val &&
    val.success === false &&
    'message' in val &&
    typeof val.message === 'string'
  );
}

export function isApiError(val: unknown): val is ApiError {
  return (
    isMaybeObject<ApiError>(val) && 'data' in val && isApiErrorData(val.data)
  );
}

/**
 * Returns the error message from an Axios error if it contains a response
 * object formatted according to our API response conventions.
 *
 * Call this in the catch block for an Axios request, directly pass the caught
 * error:
 *
 * ```
 * try {
 *   await axios.get('/api/endpoint');
 * } catch (error) {
 *   const message = apiErrorMessageFromAxiosError(error);
 * }
 * ```
 * @param error The caught error object.
 * @return the error message if the passed value is an error that contains one,
 * or `null`.
 *
 * @details Doesn't support v1 endpoints. Don't feel like making it do so. Stop
 * creating them.
 */
export function apiErrorMessageFromAxiosError(error: unknown): string {
  return (
    (axios.isAxiosError(error) &&
      isApiError(error.response) &&
      error.response.data.message) ||
    null
  );
}
