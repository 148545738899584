import React, { ComponentProps, useMemo, useState } from 'react';
import { Grid, Chip, Avatar, Stack, Skeleton } from '@mui/material';
import { TeamMemberProspectShareRow } from './TeamMemberProspectShareRow';
import { useUserState } from 'hooks/common/useUserState';
import { SimpleModal } from 'components/common/Modal/SimpleModal';
import {
  FormModal,
  getInputElementProps,
} from 'components/common/Modal/FormModal';
import {
  AutocompleteElement,
  CheckboxElement,
  SelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ShareIcon from '@mui/icons-material/Share';
import { useDispatch } from 'react-redux';
import { setSnacksQueue } from 'redux/reducers/snacks';
import {
  Role,
  TeamMember,
  TeamMembersWithPermissions,
  Permission,
} from 'modules/prospects/types';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { LoadingButton } from '@mui/lab';

type ShareProspectListProps = {
  uuid: string;
  teamMembers: TeamMember[];
  sharedWith: TeamMembersWithPermissions[];
  onTransferOwner?: () => void;
  buttonLabel?: string;
  slotProps?: {
    button?: ComponentProps<typeof LoadingButton>; // props for the Button component
  };
};

const formDefaultValues = {
  teamMembers: [] as TeamMember[],
  notifyPeople: false,
  message: '',
  popperValue: 'Viewer' as Permission,
};

export function ShareProspectList({
  uuid,
  teamMembers: teamMembersProp,
  onTransferOwner,
  sharedWith,
  buttonLabel,
  slotProps = {},
}: ShareProspectListProps) {
  const userState = useUserState();
  const dispatch = useDispatch();

  const [transferOwner] = prospectsApi.usePatchTransferOwnerMutation();
  const [changeRoles] = prospectsApi.usePatchChangeRolesMutation();

  // #region transfer ownership confirmation modal
  const [openTransferOwnershipModal, setOpenTransferOwnershipModal] =
    useState(false);
  const [changeOwnershipIndex, setChangeOwnershipIndex] = useState<
    number | null
  >(null);
  const [transferOwnershipLoading, setTransferOwnershipLoading] =
    useState(false);

  // function to accept the transfer of ownership
  const acceptTransferOwnership = async () => {
    setTransferOwnershipLoading(true);
    if (changeOwnershipIndex !== null) {
      await changePermission(changeOwnershipIndex, 'Owner');
    }
    setOpenTransferOwnershipModal(false);
    setShareModalOpen(false);
    setChangeOwnershipIndex(null);
    setTransferOwnershipLoading(false);
  };

  // #endregion

  // #region submit autocomplete form modal and share the list with team members
  const form = useForm({
    mode: 'all',
    defaultValues: formDefaultValues,
  });
  const { notifyPeople, teamMembers: teamMembersToShare } = form.watch();
  const [autocompleteModalOpen, setAutocompleteModalOpen] = useState(false);
  const [teamMembersBeingAdded, setTeamMembersBeingAdded] = useState(0); // state to keep track of how many team members are being added, TODO should be removed when these endpoints use RTK

  // team members that the list is already shared with
  const [teamMembersAlreadyShared, setTeamMembersAlreadyShared] =
    useState<TeamMembersWithPermissions[]>(sharedWith);

  // filter the team members that the list is not the user and is not already shared with
  const filteredTeamMember = useMemo(
    () =>
      teamMembersProp.filter((sharedWithTeamMember) => {
        return (
          sharedWithTeamMember.email !== userState.email &&
          teamMembersAlreadyShared.findIndex((teamMember) => {
            return teamMember.email === sharedWithTeamMember.email;
          }) === -1
        );
      }),
    [teamMembersProp, userState.email, teamMembersAlreadyShared]
  );

  // function to submit the form and share the list with the selected team members
  const shareWithPeople = async (formValues: typeof formDefaultValues) => {
    if (formValues.popperValue === 'Owner') {
      await transferOwner({
        listId: uuid,
        newOwnerEmail: formValues.teamMembers[0].email,
      });
      onTransferOwner?.();
    } else {
      const roles: Role[] = formValues.teamMembers.map(({ email }) => ({
        email,
        role: formValues.popperValue,
      }));
      setShareModalOpen(true); // show the share modal again
      setTeamMembersBeingAdded(formValues.teamMembers.length); // set the state to show the loading skeletons

      const resp = await changeRoles({
        uuid,
        notify: formValues.notifyPeople,
        message: formValues.message,
        roles,
      }).unwrap();

      if (resp.success) {
        dispatch(setSnacksQueue({ type: 'success', message: resp.message }));
        setTeamMembersBeingAdded(0); //  reset the state to hide the loading skeletons
        setTeamMembersAlreadyShared((prevTeamMembers) => [
          ...prevTeamMembers,
          ...formValues.teamMembers.map((teamMember) => ({
            ...teamMember,
            role: formValues.popperValue,
          })),
        ]);
      }
    }
  };

  // #endregion

  // #region prospect list edit sharing modal

  const [shareModalOpen, setShareModalOpen] = useState(false);

  // the various roles that can be assigned to a team member, only include Owner if teamMembersToShare.length === 1
  const roles = [
    { id: 'Viewer', label: 'Viewer' },
    { id: 'Editor', label: 'Editor' },
    ...(teamMembersToShare.length === 1
      ? [{ id: 'Owner', label: 'Owner' }]
      : []),
  ];

  // remove fullWidth for CheckBoxProps since it causes an error for CheckboxElement
  const { fullWidth, ...CheckBoxProps } = getInputElementProps({
    name: 'notifyPeople',
    label: `Notify ${teamMembersToShare.length > 1 ? 'people' : 'person'}`,
    form,
  });

  // state to keep track of which team member's permissions popper is open
  const [
    teamMembersPermissionPoppersOpen,
    setTeamMembersPermissionPoppersOpen,
  ] = useState(-1);

  // function to change the permission of a team member
  const changePermission = async (index: number, permission: Permission) => {
    const email = teamMembersAlreadyShared[index].email;

    const handleOwnerChange = async () => {
      const response = await transferOwner({
        listId: uuid,
        newOwnerEmail: email,
      }).unwrap();
      setAutocompleteModalOpen(false);
      return response;
    };

    const handleRoleChange = async () => {
      return await changeRoles({
        uuid,
        notify: false,
        message: '',
        roles: [{ email, role: permission }],
      }).unwrap();
    };

    const resp =
      permission === 'Owner'
        ? await handleOwnerChange()
        : await handleRoleChange();

    if (resp.success) {
      setTeamMembersAlreadyShared((prevTeamMembers) => [
        ...prevTeamMembers.slice(0, index),
        { ...prevTeamMembers[index], role: permission },
        ...prevTeamMembers.slice(index + 1),
      ]);
    }
  };

  // function to remove a team member from the list
  const removeFromList = async (index: number) => {
    const { email } = teamMembersAlreadyShared[index];
    const resp = await changeRoles({
      uuid,
      notify: false,
      message: '',
      roles: [{ email, role: 'None' }],
    }).unwrap();

    if (resp.success) {
      setTeamMembersAlreadyShared((prevTeamMembers) =>
        prevTeamMembers.map((teamMember) =>
          teamMember.email === email
            ? { ...teamMember, role: 'None' }
            : teamMember
        )
      );
    }
  };

  // function to change the ownership of the list
  const changeOwnership = (index: number) => {
    setOpenTransferOwnershipModal(true);
    setChangeOwnershipIndex(index);
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        onClick={() => {
          sharedWith.length
            ? setShareModalOpen(true)
            : setAutocompleteModalOpen(true);
        }}
        startIcon={<ShareIcon />}
        {...slotProps?.button}
      >
        {buttonLabel
          ? buttonLabel
          : sharedWith.length
          ? 'Edit sharing options'
          : 'Share with team members'}
      </LoadingButton>

      {/* transfer ownership confirmation modal */}
      <SimpleModal
        open={openTransferOwnershipModal}
        setOpen={setOpenTransferOwnershipModal}
        title={'Make this person the owner?'}
        subtitle="The new owner will be notified and will gain access to edit the user list. They will be able to remove you and change your permission level."
        confirmText="Yes"
        confirmLoading={transferOwnershipLoading}
        confirmColor="primary"
        onConfirm={acceptTransferOwnership}
        cancelColor="error"
        onCancel={() => {
          setChangeOwnershipIndex(null);
        }}
      />

      {/* share prospect list modal */}
      <SimpleModal
        open={shareModalOpen}
        setOpen={setShareModalOpen}
        title="Prospect list sharing options"
        subtitle="This list is currently shared with the following people:"
        onConfirm={() => setAutocompleteModalOpen(true)}
        confirmText="Add more people"
        confirmIcon={<PersonAddAltIcon />}
        onCancel={() => {
          setAutocompleteModalOpen(false);
        }}
        maxWidth="sm"
        cancelText="Close"
      >
        <Stack
          spacing={2}
          sx={{
            overflow: 'auto',
            maxHeight: '300px',
          }}
        >
          <TeamMemberProspectShareRow
            index={0}
            key={userState.email}
            teamMember={{
              email: userState.email,
              avatar: userState.profile.image,
              name: userState.profile.name,
              role: 'Owner',
              url: '',
            }}
            changePermission={changePermission}
            onRemove={removeFromList}
            changeOwnership={changeOwnership}
            disabled
          />
          {teamMembersAlreadyShared.map((teamMember, index) => (
            <TeamMemberProspectShareRow
              index={index}
              key={teamMember.email}
              teamMember={teamMember}
              changePermission={changePermission}
              onRemove={removeFromList}
              changeOwnership={changeOwnership}
              togglePermissionsPopper={() =>
                setTeamMembersPermissionPoppersOpen(
                  teamMembersPermissionPoppersOpen === index ? -1 : index
                )
              }
              isPermissionsPopperOpen={
                teamMembersPermissionPoppersOpen === index
              }
            />
          ))}
          {teamMembersBeingAdded > 0 && (
            <>
              {[...Array(teamMembersBeingAdded)].map((_, index) => (
                <Grid
                  key={`skeleton-${index}`}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Skeleton variant="circular" width={40} height={40} />
                  <Grid pl={2} item container direction="column" xs="auto">
                    <Skeleton width={100} />
                    <Skeleton width={200} />
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </Stack>
      </SimpleModal>

      {/* add team members autocomplete modal */}
      <FormModal
        open={autocompleteModalOpen}
        setOpen={setAutocompleteModalOpen}
        title="Add more team members"
        subtitle="Please select team members to share this list with. You will be able to notify them and change their permission level."
        onSubmit={shareWithPeople}
        onCancel={() => {
          if (sharedWith.length) setShareModalOpen(true);
        }}
        form={form}
        defaultValues={formDefaultValues}
        maxWidth="sm"
      >
        <Stack spacing={4}>
          <AutocompleteElement
            {...getInputElementProps({
              name: 'teamMembers',
              label: 'Select team members',
              form,
            })}
            rules={{
              required: {
                value: true,
                message: 'Please select at least one team member.',
              },
            }}
            multiple
            options={filteredTeamMember}
            autocompleteProps={{
              size: 'small',
              disablePortal: true,
              blurOnSelect: true,
              placeholder:
                teamMembersToShare.length > 0
                  ? undefined
                  : "Search for your team member's name",
              getOptionLabel: (option) => option.name ?? '',
              isOptionEqualToValue: (option, value) =>
                option.email === value.email,
              filterSelectedOptions: true,
              value: teamMembersToShare,
              renderTags: (value: readonly TeamMember[], getTagProps) =>
                value.map((teamMember: TeamMember, index: number) => {
                  const { className, ...otherProps } = getTagProps({
                    index,
                  });
                  return (
                    <Chip
                      avatar={
                        <Avatar
                          alt={'Avatar for ' + teamMember.name}
                          src={teamMember.avatar}
                        />
                      }
                      variant="outlined"
                      label={teamMember.name}
                      {...otherProps}
                      disabled={false}
                      className={className}
                    />
                  );
                }),
            }}
          />
          {teamMembersToShare.length > 0 && (
            <>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={4}>
                  <SelectElement
                    {...getInputElementProps({
                      name: 'popperValue',
                      label: 'Permission',
                      form,
                    })}
                    options={roles}
                  />
                </Grid>
                <Grid item>
                  <CheckboxElement
                    {...CheckBoxProps}
                    labelProps={{
                      sx: {
                        m: 0,
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {notifyPeople && (
                <TextFieldElement
                  {...getInputElementProps({
                    name: 'message',
                    label: 'Message',
                    form,
                  })}
                  multiline
                  rows={4}
                  placeholder={`Add a message to notify your team ${
                    teamMembersToShare.length > 1 ? 'members' : 'mate'
                  }.`}
                />
              )}
            </>
          )}
        </Stack>
      </FormModal>
    </>
  );
}
