import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Tab } from '@mui/material';
import { TabList } from '@mui/lab';

// #region types

export interface UpperCaseTab<T extends string | number> {
  value: T;
  label: string;
}

interface UpperCaseTabListProps<T extends string | number> {
  tabs: UpperCaseTab<T>[];
  onTabChange?: (event: React.SyntheticEvent, newValue: T) => void;
  slotProps?: {
    tabList?: React.ComponentProps<typeof TabList>;
    tab?: React.ComponentProps<typeof Tab>;
  };
}

// Custom component for rendering tabs with uppercase labels
// Note: Tabs don't support variants directly, so we use a custom component
export const UpperCaseTabList = <T extends string | number>({
  tabs,
  onTabChange,
  slotProps,
}: UpperCaseTabListProps<T>) => {
  const theme = useTheme();
  return (
    <TabList
      onChange={onTabChange}
      sx={{
        bgcolor: 'transparent',
        '& .Mui-selected': {
          bgcolor: 'transparent',
        },
      }}
      TabIndicatorProps={{
        sx: {
          bottom: 4,
          bgcolor: theme.palette.common.black,
        },
      }}
      {...slotProps?.tabList}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={`tab-${tab.value}-${index}`}
          label={tab.label}
          value={tab.value}
          disableRipple
          sx={{
            textTransform: 'uppercase',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 700,
            mr: 4,
            p: 0,
            minWidth: 'auto',
          }}
          {...slotProps?.tab}
        />
      ))}
    </TabList>
  );
};
