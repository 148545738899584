import { useState, useMemo } from 'react';
import { WizardInputUploadQuestion, WizardQuestion } from '../WizardConfig';
import { Stack, Typography } from '@mui/material';
import { WizardPagination } from '../WizardPagination';
import { useForm } from 'react-hook-form-mui';
import { Wizard } from '../Wizard';
import { useHistory } from 'react-router';
import { TextFieldWithUpload } from 'components/ProspectList/AutoCompleteWithUpload';
import { type Option } from 'hooks/Prospects/useIcpFilter';
import { capitalizeFirstChar } from 'utils/util';
import { endpoints } from 'variables/endpoint-urls';
import { ApiResponse } from 'utils/api-response';
import { useDispatch } from 'react-redux';
import { setSnacksQueue } from 'redux/reducers/snacks';
import {
  GetAutocompleteOrgs,
  GetAutocompletePeople,
} from '../../../redux/reducers/api/prospects';
import {
  jobTitles,
  orgSizes,
} from 'components/ProspectList/ICPBuilder/presets';
import { fieldConfigurations } from 'components/ProspectList/ICPBuilder/CsvUploadModal';
import { autocompletePlaceholder } from '../wizardUtils';
import { prospectsApi } from 'redux/reducers/api/prospects';

/**
 * Join arrays of options and remove duplicates.
 *
 * @param inputArrays {Option[][]} Any number of arrays of options.
 * @returns {Option[]} A single array of options with duplicates removed.
 */
export function mergeInputValues(...inputArrays: Option[][]): Option[] {
  const uniqueOptions: { [key: string]: Option } = {};

  inputArrays.forEach((inputArray) => {
    inputArray.forEach((option) => {
      const key = option.value;
      if (!uniqueOptions[key]) {
        uniqueOptions[key] = option;
      }
    });
  });

  return Object.values(uniqueOptions);
}

export function AutocompleteWithUploadQuestion({
  question,
  onSubmit,
  initialValue,
  onItemSelected,
  wizard,
}: {
  question: WizardQuestion & WizardInputUploadQuestion;
  onSubmit?: (response: any) => void;
  initialValue?: any;
  onItemSelected?: (values: any[]) => void;
  wizard: Wizard;
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultValues: Record<string, any> = {};

  const { data: myProspectLists } = prospectsApi.useGetMyProspectListsQuery();
  const prospectLists = useMemo(
    () => (!myProspectLists?.success ? [] : myProspectLists.prospect_lists),
    [myProspectLists]
  );

  defaultValues[question.name] = initialValue;

  const form = useForm({
    defaultValues,
  });

  const [value, setValue] = useState<Option[]>(initialValue || []);
  const label = capitalizeFirstChar(question.subType);

  function submitForm() {
    try {
      wizard.storage.saveResponse(question.id, value);
      history.push(wizard.questions.urlFor(question.nextQuestionId as string));
    } catch (e) {
      // Localstorage can't handle this much data; show a nice message.
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        dispatch(
          setSnacksQueue({
            message:
              'Your upload is too large for the wizard to handle. Try again with fewer rows.',
          })
        );
      }
    }
  }

  function onNextClick() {
    form.handleSubmit(submitForm)();
  }

  function onPreviousClick() {
    history.goBack();
  }

  let endpointFunction;
  let filterFetchedOptions;
  let fetch = true;
  let options: Option[] = [];
  let allowUpload = true;

  switch (question.subType) {
    case 'organizations':
      endpointFunction = endpoints.autocomplete.get.orgs;
      filterFetchedOptions = (
        data: ApiResponse<GetAutocompleteOrgs, 'results'>
      ) => {
        if ('results' in data) {
          return data.results.orgs.map(
            ({ organization_name, organization_uuid }) => ({
              label: organization_name,
              value: organization_uuid,
            })
          );
        } else {
          return [];
        }
      };
      break;
    case 'people':
      endpointFunction = endpoints.autocomplete.get.people;
      filterFetchedOptions = (
        data: ApiResponse<GetAutocompletePeople, 'results'>
      ) => {
        if ('results' in data) {
          return data.results.profiles.map(
            ({ profile_name, organization_name, profile_uuid }) => ({
              label: `${profile_name} (${organization_name})`,
              value: profile_uuid,
            })
          );
        } else {
          return [];
        }
      };
      break;
    case 'job-titles-keywords':
      fetch = false;
      options = jobTitles.map((title) => ({
        label: title,
        value: title,
        disabled: false,
      }));
      break;
    case 'prospect-lists':
      fetch = false;
      options = prospectLists.map((list) => ({
        label: list.name,
        value: list.uuid,
        disabled: false,
      }));
      break;
    case 'organization-size':
      fetch = false;
      options = orgSizes.map((size) => ({
        label: size,
        value: size,
      }));
      allowUpload = false;
      break;

    default:
      throw new Error(`Unknown question subType: ${question.subType}`);
  }
  return (
    <form onSubmit={form.handleSubmit(submitForm)}>
      <Stack spacing={8}>
        <Stack spacing={2}>
          <Typography fontWeight={500} variant="h6">
            {question.label}
          </Typography>
          {question.description ? (
            <Typography variant="body2">{question.description}</Typography>
          ) : null}
          <TextFieldWithUpload
            label={label}
            placeholder={
              question.placeholder ?? question.subType
                ? autocompletePlaceholder(question.subType)
                : 'Enter data to search for here'
            }
            options={options}
            allowUpload={allowUpload}
            fetch={fetch}
            isLink={false}
            onValueChanged={(newValue) => {
              setValue(newValue);
            }}
            value={value}
            onRemoveItemClicked={(option) => {
              setValue(value.filter((v) => v.value !== option.value));
            }}
            endpointFunction={endpointFunction}
            filterFetchedOptions={filterFetchedOptions}
            uploadModalFields={fieldConfigurations[question.subType]}
            textInputProps={{
              // Adds unique data attribute to text input for behavioral tracking (e.g., Pendo).
              // @ts-expect-error
              'data-autocomplete-type': question.subType,
            }}
            fileInputProps={{
              // Adds unique data attribute to button
              // @ts-expect-error
              'data-autocomplete-type': question.subType,
            }}
            uploadButtonProps={{
              // Adds unique data attribute to button
              // @ts-expect-error
              'data-autocomplete-type': question.subType,
            }}
          />
        </Stack>
        <WizardPagination
          wizard={wizard}
          question={question}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      </Stack>
    </form>
  );
}
