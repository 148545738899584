import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import LoginButton from 'components/Navbars/LoginButton';
import { useUserState } from 'hooks/common/useUserState';
import CopyrightIcon from '@mui/icons-material/Copyright';
import BlueFullLogo from 'assets/img/theme/blue-full-logo.svg';

export function FrontFooter() {
  const { isAuthenticated } = useUserState();
  return (
    <Box pt={8} sx={{ bgcolor: (theme) => theme.vdxColors.vdxGray[100] }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={6} xs={8} mb={8}>
            <img src={BlueFullLogo} width={130} height={26} alt="logo" />
          </Grid>
          <Grid item md={6} xs={4}>
            <Stack direction="row" spacing={4} justifyContent="flex-end">
              <MuiLink href="/blog" underline="hover">
                News
              </MuiLink>
              <MuiLink href="/about" underline="hover">
                About
              </MuiLink>
              <MuiLink href="/contact" underline="hover">
                Contact
              </MuiLink>
              <MuiLink href="/careers" underline="hover">
                Careers
              </MuiLink>
              <MuiLink href="/pricing" underline="hover">
                Pricing
              </MuiLink>
              {!isAuthenticated && <LoginButton />}
            </Stack>
          </Grid>
        </Grid>

        <Divider />

        <Grid pb={8} pt={8} container spacing={2}>
          <Grid item md={6} xs={8}>
            <Typography>
              Copyright <CopyrightIcon fontSize="small" />
              {` ${new Date().getFullYear()} | `}
              <a href="/terms">Terms of service</a>
              {' | '}
              <a href="/privacy">Privacy policy</a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
