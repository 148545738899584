import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { ShareProspectList } from 'components/Prospects/ShareProspectList';
import { WizardListShareQuestion, WizardQuestion } from '../WizardConfig';
import { Wizard } from '../Wizard';
import { useUserState } from 'hooks/common/useUserState';
import { teamApi } from 'redux/reducers/api/team';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { WizardPagination } from 'components/Wizard/WizardPagination';

export function ShareListQuestion({
  wizard,
  question,
  uuid,
}: {
  uuid: string;
  wizard: Wizard;
  question: WizardQuestion & WizardListShareQuestion;
}) {
  const history = useHistory();

  // team and user data
  const { team } = useUserState();
  const { data: directoryData } = teamApi.useGetDirectoryQuery(
    team.uuid || null
  );
  const teamMembers = directoryData?.success ? directoryData?.team : [];

  // get profile data for prospect list
  const { data: profileData, isLoading } =
    prospectsApi.useGetProspectListProfilesQuery(uuid);
  const sharedWith = profileData?.success ? profileData.shared_with : [];

  function onNextClick() {
    wizard.clearStorage();
    history.push('/app/myprospects/lists');
  }
  return (
    <Stack direction="column" spacing={4}>
      <Typography fontWeight={500} variant="h6">
        {question.description}
      </Typography>
      <Stack alignItems="center" justifyContent="center">
        <ShareProspectList
          uuid={uuid}
          buttonLabel="Share this list"
          teamMembers={teamMembers}
          sharedWith={sharedWith}
          slotProps={{
            button: {
              size: 'small',
              loading: isLoading,
            },
          }}
          onTransferOwner={() => {
            history.push('/app/myprospects/lists');
          }}
        />
      </Stack>
      <WizardPagination
        canGoBack={false}
        wizard={wizard}
        question={question}
        onNextClick={onNextClick}
        onPreviousClick={() => {}}
      />
    </Stack>
  );
}
export default ShareListQuestion;
