import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import type {
  WizardConfig,
  WizardQuestion,
  WizardResponse,
} from './WizardConfig';
import { MultipleChoiceQuestion } from './QuestionTypes/MultipleChoiceQuestion';
import { useHistory } from 'react-router';
import { Wizard } from './Wizard';
import { Stack, Grid, Typography, IconButton, Paper } from '@mui/material';
import { AutocompleteWithUploadQuestion } from './QuestionTypes/AutocompleteWithUploadQuestion';
import { TextInputQuestion } from 'components/Wizard/QuestionTypes/TextInputQuestion';
import { ListValueQuestion } from 'components/Wizard/QuestionTypes/ListValueQuestion';
import { WizardBreadcrumbs } from './WizardBreadcrumbs';
import { SectionLayout } from 'components/common/Widgets/SectionLayout';
import CloseIcon from '@mui/icons-material/Close';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { setSnacksQueue } from 'redux/reducers/snacks';
import { TreeSelectAutocompleteQuestion } from './QuestionTypes/TreeSelectAutocompleteQuestion';
import { Option as TreeSelectOption } from 'components/common/Inputs/TreeSelectAutocomplete';
import ShareListQuestion from './QuestionTypes/ShareListQuestion';

export function WizardQuestionPage({
  currentQuestionId,
  config,
}: {
  currentQuestionId: WizardQuestion['id'];
  config: WizardConfig;
}) {
  const wizard = new Wizard(config);
  const question = wizard.questions.byId(currentQuestionId);
  const history = useHistory();
  const dispatch = useDispatch();

  // TODO: extract. This is list-wizard specfic.
  const [listUuid, setListUuid] = useState('');

  // rtk query to update ICP params
  const [updateIcpParamsMutation] =
    prospectsApi.usePatchUpdateIcpParamsMutation();

  // rtk query to update deal values
  const [updateDealValuesMutation] = prospectsApi.usePatchDealValuesMutation();

  // rtk query to create list
  const [createListMutation] = prospectsApi.usePutCreateListMutation();

  const responseValue: WizardResponse['value'] | undefined = useMemo(
    () => wizard.storage.fetchResponse(currentQuestionId)?.value,
    [currentQuestionId, wizard.storage]
  );

  if (!question) {
    return <div>Question not found.</div>;
  }

  // if user is on the last question and doesn't want to share, redirect to the list page
  if (question.id === 'end') {
    wizard.clearStorage();
    history.push('/app/myprospects/lists');
  }

  // TODO: move this to a place where it can be defined by each wizard.

  async function handleFormSubmit(questionId: string, value: string) {
    if (questionId === 'list-name') {
      wizard.storage.saveResponse(questionId, value);
      const values = wizard.getFormPayload();
      console.log(values);
      try {
        const response = await createListMutation({
          listName: values.name,
          icpBuilder: true,
          listType: values.icp_params.list_type,
          listSource: values.list_source,
        });

        if ('data' in response && 'success' in response.data) {
          if (!response.data.success) {
            dispatch(
              setSnacksQueue({
                type: 'error',
                message: 'Error creating list: ' + response.data.message,
              })
            );
            return;
          }
        }

        if ('data' in response && 'uuid' in response.data) {
          const { uuid } = response.data;
          setListUuid(uuid);
          const numericValue = values.average_deal_size || 0;
          await updateDealValuesMutation({
            uuid,
            dealValues: {
              average_deal_size: numericValue,
            },
          });

          // Update ICP parameters
          await updateIcpParamsMutation({
            uuid,
            icpParams: JSON.stringify(values.icp_params),
          }).then(() => {
            history.push(
              wizard.questions.urlFor(question.nextQuestionId as string)
            );
          });

          // wizard.clearStorage();
        } else {
          dispatch(
            setSnacksQueue({
              type: 'error',
              message: 'error updating list',
            })
          );
        }
      } catch (error) {
        console.error('Error creating list:', error);
        dispatch(
          setSnacksQueue({
            type: 'error',
            message: 'There was an error creating your list.',
          })
        );
      }
    } else {
      wizard.storage.saveResponse(question.id, value);
      history.push(wizard.questions.urlFor(question.nextQuestionId as string));
    }
  }

  return (
    <SectionLayout
      title={config.title}
      slotProps={{
        container: {
          maxWidth: 'lg',
        },
      }}
      rightsideToolbar={
        <IconButton
          onClick={() => {
            wizard.clearStorage();
            history.push('/app/myprospects/lists');
          }}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      <Stack spacing={4}>
        {config.description && (
          <Typography fontWeight={500} variant="body1">
            {config.description}
          </Typography>
        )}
        <Grid container gap={6} wrap="nowrap">
          <Grid item xs={9}>
            <Paper sx={{ p: 4 }}>
              <Stack direction="column" spacing={8}>
                {/* Check for loadComplete flag to prevent rendering before we check localstorage. */}
                {question.type === 'multiple-choice' || !question.type ? (
                  <MultipleChoiceQuestion
                    wizard={wizard}
                    question={question}
                    onAnswerClick={(answer) => {
                      const value = wizard.questions.valueForMCAnswer(
                        question,
                        answer
                      );

                      // Clear following responses if the current response has changed, so that we don't end up
                      //  with more than one response flow in storage.
                      if (
                        wizard.storage.responseHasChanged(question.id, value)
                      ) {
                        wizard.storage.clearSubsequentResponses(question.id);
                      }

                      wizard.storage.saveResponse(question.id, value);

                      history.push(
                        wizard.questions.urlFor(answer.nextQuestionId)
                      );
                    }}
                  />
                ) : null}

                {question.type === 'input-upload' ? (
                  <AutocompleteWithUploadQuestion
                    initialValue={responseValue || []}
                    question={question}
                    wizard={wizard}
                  />
                ) : null}

                {question.type === 'text-input' ? (
                  <TextInputQuestion
                    wizard={wizard}
                    initialValue={responseValue as string}
                    question={question}
                    onSubmitForm={(value) =>
                      handleFormSubmit(question.id, value)
                    }
                  />
                ) : null}

                {question.type === 'list-value' ? (
                  <ListValueQuestion
                    wizard={wizard}
                    question={question}
                    initialValue={responseValue as string}
                  />
                ) : null}

                {question.type === 'tree-select-autocomplete' ? (
                  <TreeSelectAutocompleteQuestion
                    wizard={wizard}
                    question={question}
                    initialValue={responseValue as TreeSelectOption<string>[]}
                  />
                ) : null}

                {question.type === 'share' ? (
                  <ShareListQuestion
                    wizard={wizard}
                    question={question}
                    uuid={listUuid}
                  />
                ) : null}
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper sx={{ p: 4 }}>
              <WizardBreadcrumbs
                wizard={wizard}
                currentQuestionId={currentQuestionId}
              />
            </Paper>
          </Grid>
        </Grid>
      </Stack>
    </SectionLayout>
  );
}
