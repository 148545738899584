import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookies from 'js-cookie';
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: (headers, { type }) => {
      const csrfToken = cookies.get('csrftoken');
      if (csrfToken) {
        headers.set('X-CSRFToken', csrfToken);
      }
    },
  }),
  tagTypes: [
    'EventAttendees',
    'EventSponsors',
    'EventSummary',
    'Prospects',
    'EventPlanner',
    'PlannerEvent',
    'PlannerViews',
    'Search',
    'Directory',
    'ProspectLists',
    'Credits',
    'UserState',
    'Settings',
    'EventReports',
    'Options.ProspectList',
    'Subindustries',
    'Industries',
    'IndustryGroups',
    'Sectors',
  ],
  endpoints: (builder) => ({}),
});
