import React, { createContext, useContext, useState } from 'react';
import { Option } from 'hooks/Prospects/useIcpFilter';
import {
  ProspectListPermissions,
  TeamMembersWithPermissions,
} from 'modules/prospects/types';

export type IPLContext = {
  formData: {
    name: string;
    list_source?: string;
    list_type?: string; // this is different from ICP params list_type
    smart_list_status: 'ready' | 'pending' | null;
    average_deal_size: number;
    number_of_deals: number;
    icp_params: {
      list_type: string; // this is to be updated on the backend to list_source.
      full_titles?: string[];
      include_job_titles?: string[];
      exclude_job_titles?: string[];
      include_prospect_list?: string[];
      include_prospect_list_options?: Option[];
      exclude_prospect_list?: string[];
      exclude_prospect_list_options?: Option[];
      industry?: string[];
      organization_size?: string[];
      organization_size_buckets?: string[];
      organization_options?: string[];
      crm_company_options?: Option[];
      crm_people_options?: Option[];
      crm_organization_sizes?: Option[];
      crm_include_job_titles_options?: Option[];
      crm_exclude_job_titles_options?: Option[];
      crm_include_prospect_list_options?: Option[];
      crm_exclude_prospect_list_options?: Option[];
      include_deal_stage_options?: Option[];
      exclude_deal_stage_options?: Option[];
      include_deal_owner_options?: Option[];
      exclude_deal_owner_options?: Option[];
      people_options?: string[];
      contact_owner_options?: Option[];
      company_owner_options?: Option[];
      deal?: {
        'Last activity date': {
          before: string;
          after: string;
        };
        'Close date': {
          before: string;
          after: string;
        };
        'Created date': {
          before: string;
          after: string;
        };
      };
    };
    my_permissions: ProspectListPermissions[];
    shared_with: TeamMembersWithPermissions[];
  };
  setFormData: React.Dispatch<
    React.SetStateAction<{
      name: string;
      list_type?: string;
      list_source?: string;
      smart_list_status: 'ready' | 'pending' | null;
      average_deal_size: number;
      number_of_deals: number;
      icp_params: {
        list_type: string;
        full_titles?: string[];
        include_job_titles?: string[];
        exclude_job_titles?: string[];
        include_prospect_list?: string[];
        include_prospect_list_options?: Option[];
        exclude_prospect_list?: string[];
        exclude_prospect_list_options?: Option[];
        organization_size?: string[];
        organization_size_buckets?: string[];
        organization_options?: string[];
        crm_company_options?: Option[];
        crm_people_options?: Option[];
        crm_organization_sizes?: Option[];
        crm_include_job_titles_options?: Option[];
        crm_exclude_job_titles_options?: Option[];
        crm_include_prospect_list_options?: Option[];
        crm_exclude_prospect_list_options?: Option[];
        include_deal_stage_options?: Option[];
        exclude_deal_stage_options?: Option[];
        include_deal_owner_options?: Option[];
        exclude_deal_owner_options?: Option[];
        people_options?: string[];
        contact_owner_options?: Option[];
        company_owner_options?: Option[];
        deal?: {
          'Last activity date': {
            before: string;
            after: string;
          };
          'Close date': {
            before: string;
            after: string;
          };
          'Created date': {
            before: string;
            after: string;
          };
        };
      };
      my_permissions: ProspectListPermissions[];
      shared_with: TeamMembersWithPermissions[];
    }>
  >;
};

// Create the context
export const PLContext = createContext<IPLContext>({} as IPLContext);

// Create the provider
export const PLProvider = ({ children }: { children: React.ReactNode }) => {
  const [formData, setFormData] = useState<IPLContext['formData']>({
    name: '',
    list_source: '',
    list_type: '',
    smart_list_status: null,
    average_deal_size: 0,
    number_of_deals: 0,
    icp_params: {
      list_type: 'Personas',
      full_titles: [],
      include_job_titles: [],
      exclude_job_titles: [],
      include_prospect_list: [],
      include_prospect_list_options: [],
      exclude_prospect_list: [],
      exclude_prospect_list_options: [],
      organization_size: [],
      organization_size_buckets: [],
      organization_options: [],
      crm_company_options: [],
      crm_people_options: [],
      crm_organization_sizes: [],
      crm_include_job_titles_options: [],
      crm_exclude_job_titles_options: [],
      crm_include_prospect_list_options: [],
      crm_exclude_prospect_list_options: [],
      include_deal_stage_options: [],
      exclude_deal_stage_options: [],
      include_deal_owner_options: [],
      exclude_deal_owner_options: [],
      people_options: [],
      contact_owner_options: [],
      company_owner_options: [],
      deal: {
        'Last activity date': {
          before: '',
          after: '',
        },
        'Close date': {
          before: '',
          after: '',
        },
        'Created date': {
          before: '',
          after: '',
        },
      },
    },
    my_permissions: [],
    shared_with: [],
  });

  return (
    <PLContext.Provider value={{ formData, setFormData }}>
      {children}
    </PLContext.Provider>
  );
};

// Custom hook to use the context
export const usePLContext = () => {
  const context = useContext(PLContext);

  return context;
};
