import cns from 'classnames';
import {
  AutocompleteState,
  createAutocomplete,
} from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import React, { FormEvent, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  searchClient,
  INDEX_NAME,
  searchStateToUrl,
  urlToSearchState,
  INDEX_NAME_BY_TYPE,
} from 'utils/algoliaUtils';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { sendAnalyticsEvent } from 'analytics';
import 'styles/Autocomplete.scss';
import { strings } from 'variables/messages';
import { FunctionComponent } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import { SearchState } from 'react-instantsearch-core';

const getKeyBySourceId = (sourceId: 'companies' | 'events' | 'people') =>
  ({
    companies: { urlParam: 'sponsors', stateKey: 'sponsors' },
    events: { urlParam: 'query', stateKey: 'query' },
    people: { urlParam: 'people', stateKey: 'attendees.n' },
  }[sourceId]);

interface OnItemSelectOptions
  extends Pick<
    AutocompleteImplProps,
    'routerPush' | 'routerLocationSearch' | 'setSearchState'
  > {
  setIsOpen: (isOpen: boolean) => void;
  isSearchPage: boolean;
  value: string;
  fieldType: string;
  urlParam?: string;
  itemUrl?: string;
}

const onItemSelect = ({
  setIsOpen,
  isSearchPage,
  value,
  routerPush,
  routerLocationSearch,
  setSearchState,
  fieldType,
  urlParam,
  itemUrl,
}: OnItemSelectOptions) => {
  setIsOpen(false);
  if (itemUrl) {
    routerPush(itemUrl);
  } else if (!isSearchPage) {
    const queryString = value
      ? `?${urlParam || fieldType}=${encodeURIComponent(value)}`
      : '';
    routerPush(`/app/events/search${queryString}`);
  } else {
    const { searchState } = urlToSearchState(routerLocationSearch);
    const newState = {
      ...searchState,
      refinementList: {
        ...searchState.refinementList,
      },
      page: 1,
    };

    const url = searchStateToUrl({ searchState: newState });

    routerPush(url);
    setSearchState(newState);
  }
};

export interface AutocompleteImplProps {
  searchState: SearchState;
  setSearchState: (
    newState: SearchState | ((oldState: SearchState) => SearchState)
  ) => void;
  routerPush(url: string): void;
  routerLocationSearch: string;
  isSearchPage?: boolean;
  /** Use Rounded look-and-feel of search page. */
  rounded?: boolean;
  initialQuery?: string;
  size?: 'small' | 'medium';
  scrollFix?: () => void; // manually scrolls the input element into view after it's been focused.
}

type NormalizedAutocompleteItem = {
  objectID: string;
  name: string;
  url?: string;
};

export type AutocompleteItem =
  | NormalizedAutocompleteItem
  | {
      objectID: string;
      n: string;
      s: string;
      u: string;
    };

function _normalizeItem(item: AutocompleteItem): NormalizedAutocompleteItem {
  if ('name' in item) {
    return item;
  } else {
    return {
      objectID: item.objectID,
      name: item.n,
    };
  }
}

function _normalizeItemWithUrl(
  item: AutocompleteItem,
  sourceId?: 'events' | 'companies' | 'people' | string
) {
  const urlPrefix =
    sourceId === 'companies'
      ? '/app/org/'
      : sourceId === 'people'
      ? '/app/profile/'
      : '';
  if (!urlPrefix && sourceId !== 'events') {
    console.warn("Couldn't determine URL prefix for source ID:", sourceId);
  }
  if ('name' in item) {
    return item;
  } else {
    return { ..._normalizeItem(item), url: urlPrefix + `${item.s}/${item.u}` };
  }
}

export const AutocompleteImpl: FunctionComponent<AutocompleteImplProps> = (
  props
) => {
  const [autocompleteState, setAutocompleteState] = React.useState<
    AutocompleteState<AutocompleteItem>
  >({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '',
    activeItemId: null,
    status: 'idle',
  });

  const {
    routerLocationSearch,
    routerPush,
    size = 'medium',
    scrollFix = () => document?.querySelector('html')?.scrollTo(0, 0),
  } = props;

  const [fixedSearchBar, setFixedSearchBar] = useState(false);

  const [querySubmitted, setQuerySubmitted] = React.useState(false);
  const { isSearchPage = false, rounded = isSearchPage } = props;
  const closeFixedSearchBar = () => setFixedSearchBar(false);

  const debug = Boolean(process.env.REACT_APP_AUTOCOMPLETE_DEBUG);
  const autocomplete = React.useMemo(
    () =>
      createAutocomplete<AutocompleteItem, FormEvent<any>, any, any>({
        debug, // debug = true keeps algolia autocomplete open even if user clicks outside.
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        initialState: {
          query: props.initialQuery || '',
        },
        placeholder: `${debug ? 'DEBUG ' : ''}${
          strings.search.algolia.search_box_placeholder
        }`,
        getSources() {
          return [
            {
              sourceId: 'events',
              sourceName: 'Events',
              getItems({ query }) {
                setQuerySubmitted(false);
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: INDEX_NAME,
                      query,
                      params: {
                        hitsPerPage: 3,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                      },
                    },
                  ],
                });
              },
              onSelect({ setIsOpen, setQuery, item }) {
                closeFixedSearchBar();
                const normalItem = _normalizeItemWithUrl(item);
                setQuery(normalItem.name);
                setIsOpen(false);
                sendAnalyticsEvent(
                  'User Interaction',
                  'Search events',
                  normalItem.name
                );
                sendAnalyticsEvent(
                  'User Interaction',
                  'Search autocomplete',
                  normalItem.name
                );
                if (normalItem.url) {
                  routerPush(normalItem.url);
                } else if (!isSearchPage) {
                  const queryString = normalItem.name
                    ? `?query=${encodeURIComponent(normalItem.name)}`
                    : '';
                  routerPush(`/app/events/search${queryString}`);
                } else {
                  props.setSearchState({
                    ...props.searchState,
                    query: normalItem.name,
                    page: 1,
                  });
                }
              },
            },
            {
              sourceId: 'companies',
              sourceName: 'Companies',
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: INDEX_NAME_BY_TYPE.org,
                      query,
                      params: {
                        hitsPerPage: 3,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                      },
                    },
                  ],
                });
              },
              onSelect({ setIsOpen, item }) {
                closeFixedSearchBar();
                const normalItem = _normalizeItemWithUrl(item, 'companies');
                sendAnalyticsEvent(
                  'User Interaction',
                  'Search events',
                  normalItem.name
                );
                sendAnalyticsEvent(
                  'User Interaction',
                  'Search autocomplete',
                  normalItem.name
                );
                onItemSelect({
                  setIsOpen,
                  isSearchPage,
                  value: normalItem.name,
                  itemUrl: normalItem.url,
                  routerPush,
                  routerLocationSearch,
                  setSearchState: props.setSearchState,
                  fieldType: 'sponsors',
                });
              },
            },
            {
              sourceId: 'people',
              sourceName: 'People',
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: INDEX_NAME_BY_TYPE.profile,
                      query,
                      params: {
                        hitsPerPage: 3,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                      },
                    },
                  ],
                });
              },
              onSelect({ setIsOpen, item }) {
                const normalItem = _normalizeItemWithUrl(item);

                closeFixedSearchBar();
                sendAnalyticsEvent(
                  'User Interaction',
                  'Search events',
                  normalItem.name
                );
                sendAnalyticsEvent(
                  'User Interaction',
                  'Search autocomplete',
                  normalItem.name
                );
                onItemSelect({
                  setIsOpen,
                  isSearchPage,
                  value: normalItem.name,
                  routerPush,
                  routerLocationSearch,
                  setSearchState: props.setSearchState,
                  fieldType: 'attendees.n',
                  urlParam: 'people',
                });
              },
            },
          ];
        },
        ...props,
        onSubmit({ state }) {
          setIsOpen(false);
          setQuerySubmitted(true);
          closeFixedSearchBar();
          sendAnalyticsEvent('User Interaction', 'Search events', state.query);
          if (!isSearchPage) {
            const queryString = state.query
              ? `?query=${encodeURIComponent(state.query)}`
              : '';

            routerPush(`/app/events/search${queryString}`);
          } else {
            props.setSearchState({
              ...props.searchState,
              query: state.query,
              page: 1,
              initialLoad: false,
            });
          }
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  );
  const inputRef = React.useRef<HTMLInputElement>(null);
  const formRef = React.useRef(null);
  const panelRef = React.useRef(null);
  const { getEnvironmentProps, setQuery, setIsOpen } = autocomplete;

  React.useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined;
    }

    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    });

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [getEnvironmentProps, formRef, inputRef, panelRef]);

  const formClasses = {
    aaInputWrapperSearchButton: rounded
      ? 'aaInputWrapperSearchButton'
      : 'aaInputWrapperSearchButtonFront',
    aisSearchButton: rounded ? 'aisSearchButton' : 'aisSearchButtonFront',
    aaForm: rounded ? 'aaForm' : 'aaFormFront',
  };

  const searchButtonLabel = rounded ? 'Find' : 'Search Events';

  // @ts-ignore
  return (
    <div
      className={cns({
        // eslint-disable-next-line
        ['fixedSearchBar']: fixedSearchBar,
      })}
    >
      <ClickAwayListener
        onClickAway={() => {
          setIsOpen(false);
          closeFixedSearchBar();
        }}
      >
        <div
          className={cns(
            'aaAutocomplete',
            isSearchPage ? 'autocomplete--search' : 'autocomplete--front'
          )}
          {...autocomplete.getRootProps({})}
        >
          <form
            className={cns(
              formClasses.aaForm,
              size === 'small' ? 'aaForm--small' : ''
            )}
            {...autocomplete.getFormProps({ inputElement: inputRef.current })}
          >
            {fixedSearchBar && (
              <Box display={{ xs: 'block', sm: 'none' }} mr={2}>
                <KeyboardBackspaceIcon
                  style={{ color: '#3620d8' }}
                  onClick={() => {
                    closeFixedSearchBar();
                  }}
                ></KeyboardBackspaceIcon>
              </Box>
            )}
            <div className={'aaInputWrapperPrefix'}>
              <label className={'aaLabel'} {...autocomplete.getLabelProps({})}>
                <button className={'aaSubmitButton'}>
                  <SearchIcon />
                </button>
              </label>
            </div>
            <div className={'aaInputWrapper'}>
              {/*@ts-ignore*/}
              <input
                className={'aaInput'}
                ref={inputRef}
                // placeholder="{strings.search.algolia.search_box_placeholder}"
                {...autocomplete.getInputProps({
                  inputElement: inputRef.current,
                })}
                onFocus={() => {
                  /*
                   * HACK: Safari on iOS automagically scrolls to an input field
                   * when it's focused, which has a buggy interaction with
                   * `position: fixed` that causes the search bar to render
                   * off-screen and actively resists the layout being fixed more
                   * sanely.
                   *
                   * This tries to detect this has happened and scrolls the
                   * document back to the top which seems to fix the problem.
                   */
                  if (!fixedSearchBar) {
                    for (let timeout = 25; timeout <= 600; timeout += 25) {
                      window.setTimeout(() => {
                        if (
                          inputRef.current &&
                          inputRef.current.getBoundingClientRect().top < 0
                        ) {
                          scrollFix?.();
                        }
                      }, timeout);
                    }
                  }
                  setFixedSearchBar(true);
                }}
              />
            </div>
            {autocompleteState.query && (
              <div className={'aaInputWrapperSuffix'}>
                <button
                  className={'aaClearButton'}
                  title="Clear"
                  type="reset"
                  onClick={() => {
                    setIsOpen(false);
                    closeFixedSearchBar();
                  }}
                >
                  <ClearIcon />
                </button>
              </div>
            )}
            <div
              className={cns(
                formClasses.aaInputWrapperSearchButton,
                size === 'small' ? 'aaInputWrapperSearchButton--small' : ''
              )}
            >
              <button
                className={cns(
                  formClasses.aisSearchButton,
                  size === 'small' ? 'aisSearchButton--small' : ''
                )}
              >
                {searchButtonLabel}
              </button>
            </div>
          </form>

          {autocompleteState.isOpen && !querySubmitted && (
            <div
              ref={panelRef}
              className={cns('aaPanel', 'aaPanelDesktop')}
              {...autocomplete.getPanelProps({})}
            >
              <div className={cns('aaPanelLayout', 'aaPanelScrollable')}>
                {autocompleteState.collections.map((collection, index) => {
                  const { source, items } = collection;

                  return (
                    <section key={`source-${index}`} className={'aaSource'}>
                      <div className={'aaSourceHeader'}>
                        <span className={'aaSourceHeaderTitle'}>
                          {/*@ts-ignore*/}
                          {source.sourceName}
                        </span>
                        <div className={'aaSourceHeaderLine'}></div>
                      </div>

                      {items.length > 0 && (
                        <ul
                          className={'aaList'}
                          {...autocomplete.getListProps()}
                        >
                          {items.map((item) => {
                            const normalItem = _normalizeItemWithUrl(
                              item,
                              source.sourceId
                            );
                            return (
                              <li
                                key={normalItem.objectID}
                                className={'aaItem'}
                                {...autocomplete.getItemProps({ item, source })}
                                onClick={(event) => {
                                  closeFixedSearchBar();
                                  event.stopPropagation();
                                  const query =
                                    (event.target as { innerText?: string })
                                      .innerText ?? '';
                                  setIsOpen(false);
                                  // @ts-ignore
                                  const key = getKeyBySourceId(source.sourceId);

                                  if (key.urlParam === 'query') {
                                    setQuery(query);
                                  }
                                  sendAnalyticsEvent(
                                    'User Interaction',
                                    'Search events',
                                    query
                                  );
                                  sendAnalyticsEvent(
                                    'User Interaction',
                                    'Search autocomplete',
                                    query
                                  );
                                  if (normalItem.url) {
                                    routerPush(normalItem.url);
                                  } else if (!isSearchPage) {
                                    const queryString = query
                                      ? `?${key.urlParam}=${encodeURIComponent(
                                          query
                                        )}`
                                      : '';

                                    routerPush(
                                      `/app/events/search${queryString}`
                                    );
                                  } else {
                                    const { searchState } =
                                      urlToSearchState(routerLocationSearch);
                                    const newState = {
                                      ...searchState,
                                      ...(key.urlParam !== 'query'
                                        ? {
                                            refinementList: {
                                              ...searchState.refinementList,
                                              [key.stateKey]: [
                                                // @ts-ignore
                                                ...searchState.refinementList[
                                                  key.stateKey
                                                ],
                                                query,
                                              ],
                                            },
                                          }
                                        : { query: query }),
                                      page: 1,
                                    };

                                    const url = searchStateToUrl({
                                      searchState: newState,
                                    });

                                    routerPush(url);
                                    props.setSearchState(newState);
                                  }
                                }}
                              >
                                <div className={'aaItemWrapper'}>
                                  <div className={'aaItemContent'}>
                                    <div className={'aaItemTitle'}>
                                      {normalItem.name}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </section>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};
