import React, { useCallback } from 'react';
import { Hit } from 'react-instantsearch-core';
import { CardContent, Divider, Input, Stack } from '@mui/material';
import Search from '@mui/icons-material/Search';
import { ExploreFilterButtonChip } from './ExploreFilterButtonChip';
import { RefinementHit } from './ExploreFilterButton';

interface ExploreFilterButtonContentProps {
  selectedItems: string[];
  placeholder: string;
  itemsToRefine: Hit[] | RefinementHit[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  getItemComparator: (item: Hit | RefinementHit) => string;
  searchForItems: (searchText: string) => void;
  hideCount?: boolean;
  showSearchBox?: boolean;
}

export const ExploreFilterButtonContent = ({
  selectedItems,
  hideCount,
  showSearchBox,
  placeholder,
  itemsToRefine,
  setSelectedItems,
  getItemComparator,
  searchForItems,
}: ExploreFilterButtonContentProps) => {
  const selectItem = useCallback(
    (item: Hit | RefinementHit) => {
      const itemComparator = getItemComparator(item);
      if (selectedItems.includes(itemComparator)) {
        const newRefinement = selectedItems.slice();
        const indexToRemove = newRefinement.findIndex(
          (refinement) => refinement === itemComparator
        );
        newRefinement.splice(indexToRemove, 1);
        setSelectedItems(newRefinement);
      } else {
        setSelectedItems([...selectedItems, itemComparator]);
      }
    },
    [selectedItems, getItemComparator, setSelectedItems]
  );

  return (
    <CardContent sx={{ p: '0 !important' }}>
      {showSearchBox && (
        <Input
          startAdornment={<Search sx={{ pr: 1 }} fontSize="small" />}
          placeholder={placeholder}
          fullWidth
          disableUnderline
          margin="dense"
          sx={{
            p: 2,
          }}
          onChange={(e) => {
            searchForItems(e.target.value);
          }}
        />
      )}
      {showSearchBox && <Divider />}
      <Stack
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="center"
        direction="row"
        sx={{
          ml: -1,
          p: 4,
          pb: 0,
          maxHeight: 300,
          overflow: 'auto',
        }}
      >
        {itemsToRefine.map((item, index) => {
          const itemComparator = getItemComparator(item);
          const itemIsRefined = selectedItems.includes(itemComparator);
          return (
            <ExploreFilterButtonChip
              key={`${item.label}-${item.count}-${index}`}
              item={item}
              hideCount={hideCount}
              itemIsRefined={itemIsRefined}
              selectItem={selectItem}
            />
          );
        })}
      </Stack>
    </CardContent>
  );
};
