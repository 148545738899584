import { Stack, Typography } from '@mui/material';
import { TextFieldElement, useForm } from 'react-hook-form-mui';
import {
  WizardQuestion,
  WizardTextInputQuestion,
} from 'components/Wizard/WizardConfig';
import { WizardPagination } from 'components/Wizard/WizardPagination';
import { useHistory } from 'react-router';
import { Wizard } from 'components/Wizard/Wizard';

export function TextInputQuestion({
  question,
  initialValue,
  wizard,
  onSubmitForm,
}: {
  question: WizardQuestion & WizardTextInputQuestion;
  initialValue: string;
  wizard: Wizard;
  onSubmitForm: (value: string) => Promise<void>;
}) {
  const history = useHistory();

  const defaultValues: Record<string, any> = {};
  defaultValues[question.name] = initialValue;

  const form = useForm({
    defaultValues,
  });

  function submitForm() {
    onSubmitForm(form.getValues()[question.name]);
  }

  function onNextClick() {
    form.handleSubmit(submitForm)();
  }

  function onPreviousClick() {
    history.goBack();
  }

  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        <Typography fontWeight={500} variant="h6">
          {question.label}
        </Typography>
        {question.description ? (
          <Typography variant="body2">{question.description}</Typography>
        ) : null}
        <form onSubmit={form.handleSubmit(submitForm)}>
          <TextFieldElement
            fullWidth
            control={form.control}
            name={question.name}
            required
            aria-required
            validation={{
              required: 'This field is required.',
            }}
            placeholder={question.placeholder}
          />
        </form>
      </Stack>
      <WizardPagination
        wizard={wizard}
        question={question}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
      />
    </Stack>
  );
}
