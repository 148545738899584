import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  ButtonProps,
} from '@mui/material';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Breakpoint, useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { FieldValues, SubmitHandler } from 'react-hook-form';

export interface SimpleModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string | React.ReactNode;

  // optional props
  confirmText?: string;
  children?: ReactNode; // optional children to display underneath the text
  subtitle?: string | React.ReactNode;
  onConfirm?: () => Promise<void> | SubmitHandler<FieldValues> | void; // async function to do something when the confirm button is clicked
  onCancel?: () => Promise<void> | void; // async function to do something when the cancel button is clicked
  cancelColor?: ComponentProps<typeof Button>['color']; // based on the Button component's color prop since MUI doesnt have a defined type for this
  confirmColor?: ComponentProps<typeof Button>['color']; // based on the Button component's color prop since MUI doesnt have a defined type for this
  cancelIconPosition?: 'left' | 'right'; // position of the confirm icon (default 'left')
  confirmIconPosition?: 'left' | 'right'; // position of the confirm icon (default 'left')
  cancelIcon?: React.ReactNode;
  confirmIcon?: React.ReactNode;
  disableCancel?: boolean; // cancel button is disabled aka greyed out (default false)
  disableConfirm?: boolean; // confirm button is disabled aka greyed out (default false)
  hideCancel?: boolean; // hide the cancel button (default false)
  hideConfirm?: boolean; // hide the confirm button (default false)
  confirmLoading?: boolean; // loading state for the confirm button (default false)
  maxWidth?: Breakpoint; // max width of the modal (default 'xs')
  cancelText?: string; // default 'Cancel'
  closeAfterSubmit?: boolean; // close the modal after the confirm button is clicked (default true)
  slotProps?: {
    dialogContentProps?: ComponentProps<typeof DialogContent>; // props for the DialogContent component
    dialogTitleProps?: ComponentProps<typeof DialogTitle>; // props for the DialogTitle component
  };
}

// simple modal with a title, subtitle, and confirm/cancel buttons
export const SimpleModal = ({
  open,
  setOpen,
  title,
  confirmText = 'Confirm',
  cancelText = 'Cancel',

  // optional props no default values
  subtitle,
  onConfirm,
  children,

  // optional props with default values
  onCancel = () => {},
  cancelColor = 'primary',
  cancelIcon = <ClearIcon />,
  confirmColor = 'primary',
  confirmIcon = <CheckIcon />,
  cancelIconPosition = 'left',
  confirmIconPosition = 'left',
  disableCancel = false,
  disableConfirm = false,
  hideCancel = false,
  hideConfirm = false,
  confirmLoading = false,
  maxWidth = 'xs',
  closeAfterSubmit = true,
  slotProps = {},
}: PropsWithChildren<SimpleModalProps>) => {
  const { dialogContentProps, dialogTitleProps } = slotProps;
  const theme = useTheme();

  const handleConfirm = async (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent default form submission
    try {
      if (onConfirm instanceof Function) {
        await onConfirm();
      }
    } finally {
      if (closeAfterSubmit) setOpen(false);
    }
  };

  const handleCancel = async () => {
    try {
      if (onCancel instanceof Function) {
        await onCancel();
      }
    } finally {
      setOpen(false);
    }
  };

  const cancelButtonProps: ButtonProps = {
    variant: 'text' as const,
    startIcon: cancelIconPosition === 'left' ? cancelIcon : undefined,
    endIcon: cancelIconPosition === 'right' ? cancelIcon : undefined,
    color: cancelColor,
    onClick: handleCancel,
    disabled: disableCancel,
  };

  const confirmButtonProps: LoadingButtonProps = {
    variant: 'text' as const,
    type: 'submit' as const,
    startIcon: confirmIconPosition === 'left' ? confirmIcon : undefined,
    endIcon: confirmIconPosition === 'right' ? confirmIcon : undefined,
    color: confirmColor,
    onClick: handleConfirm,
    loading: confirmLoading,
    disabled: disableConfirm,
  };

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      onClose={handleCancel}
      PaperProps={{
        sx: {
          bgcolor: 'transparent', // so the border radius doesn't show a tiny white dot
          overflow: 'visible', // when the content is too big, the dialog will not have a scrollbar, but the content will display outside
        },
      }}
    >
      {typeof title === 'string' ? (
        <DialogTitle {...dialogTitleProps}>{title}</DialogTitle>
      ) : (
        title
      )}
      <Box
        sx={{
          bgcolor: theme.palette.background.paper,
          pt: 4,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
      >
        {subtitle && (
          <DialogTitle
            sx={{
              pt: 0,
            }}
          >
            {typeof subtitle === 'string' ? (
              <Typography variant="body1">{subtitle}</Typography>
            ) : (
              subtitle
            )}
          </DialogTitle>
        )}

        {/* optional children to display underneath the title/subtitle */}
        {children && (
          <DialogContent
            sx={{
              pt: `${theme.spacing(1)}px !important`,
              bgcolor: theme.palette.background.paper,
            }}
            {...dialogContentProps}
          >
            {children}
          </DialogContent>
        )}

        {(!hideCancel || !hideConfirm) && (
          <DialogActions sx={{ pt: 0 }}>
            {!hideCancel && (
              <Button {...cancelButtonProps}>{cancelText}</Button>
            )}

            {!hideConfirm && (
              <LoadingButton {...confirmButtonProps}>
                {confirmText}
              </LoadingButton>
            )}
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
};
