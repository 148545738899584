// TODO: change breadcrumbs name to stepper
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
} from '@mui/material';
import { WizardQuestion } from './WizardConfig';
import { Wizard } from './Wizard';
import { formatNumber } from 'utils/util';
interface WizardBreadcrumbsProps {
  wizard: Wizard;
  currentQuestionId: WizardQuestion['id'];
}

export function WizardBreadcrumbs({
  wizard,
  currentQuestionId,
}: WizardBreadcrumbsProps) {
  const { push } = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const storage = wizard.fetchStorage();

  useEffect(() => {
    if (storage && storage.responses) {
      const currentStepIndex = storage.responses.findIndex(
        (response) => response.questionId === currentQuestionId
      );

      if (currentStepIndex !== -1) {
        setActiveStep(currentStepIndex);
      } else {
        //  else, currentQuestionId is not in responses yet so handle what the next index should be if currentQuestionId were added
        setActiveStep(storage.responses.length);
      }
    }
  }, [currentQuestionId, storage]);

  return (
    <Stepper
      sx={{
        '& .MuiStep-root:first-of-type .MuiStepLabel-root': {
          paddingTop: 0,
        },
      }}
      activeStep={activeStep}
      orientation="vertical"
    >
      {storage?.responses.map((response) => {
        const question = wizard.questions.byId(response.questionId);
        const value = response.value;

        return (
          <Step
            key={response.questionId}
            onClick={() => {
              push(wizard.questions.urlFor(response.questionId));
            }}
            sx={{ cursor: 'pointer' }}
          >
            <StepLabel>{question.label}</StepLabel>
            <StepContent TransitionProps={{ in: true }}>
              <Typography>
                {Array.isArray(value)
                  ? `${formatNumber(value.length)} added`
                  : value}
              </Typography>
            </StepContent>
          </Step>
        );
      })}
      {/* render the current step if not yet in storage responses */}
      {storage &&
        !storage.responses.some(
          (response) => response.questionId === currentQuestionId
        ) && (
          <Step key={currentQuestionId}>
            <StepLabel>
              {wizard.questions.byId(currentQuestionId).label}
            </StepLabel>
          </Step>
        )}
    </Stepper>
  );
}
