import React from 'react';
import { Chip } from '@mui/material';
import { Close, OpenInNew } from '@mui/icons-material';
import { Option } from 'hooks/Prospects/useIcpFilter';

export function CustomTag({
  option,
  isLink = false,
  handleDelete,
}: {
  option: Option | undefined;
  isLink?: boolean;
  handleDelete?: () => void;
}) {
  return (
    <Chip
      label={option?.label}
      deleteIcon={<Close fontSize="small" sx={{ pr: 1 }} />}
      color="lightblue"
      onDelete={handleDelete}
      onClick={() => {
        if (isLink) {
          window.open(`/app/prospectlist/${option?.value}`, '_blank');
        }
      }}
      {...(isLink && {
        icon: <OpenInNew fontSize="small" sx={{ pl: 1 }} />,
      })}
    />
  );
}
