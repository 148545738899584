import { WizardQuestionPage } from 'components/Wizard/WizardQuestionPage';
import { withAppAuthenticationRequired } from 'components/common/Auth/withAppAuthenticationRequired';
import { listWizardConfig } from './listWizardConfig';
import { useParams } from 'react-router';
import { PLProvider } from 'routes/newprospectslists/ListBuilderContext';

function _NewListWizardPage() {
  const { questionId } = useParams<{ questionId: string }>(); // Type assertion

  return (
    <PLProvider>
      <WizardQuestionPage
        config={listWizardConfig}
        currentQuestionId={
          questionId ? questionId : listWizardConfig.questions[0].id
        }
      />
    </PLProvider>
  );
}

export const NewListWizardPage =
  withAppAuthenticationRequired(_NewListWizardPage);

export default NewListWizardPage;
